import { Button, Flex, Input, Space, Typography } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import * as Yup from "yup";

import styles from "./change-password-form.module.scss";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Мінімум 8 символів")
    .matches(/[a-z]+/, "Мінімум одна маленька літера")
    .matches(/[A-Z]+/, "Мінімум одна велика літера")
    .matches(/\d+/, "Мінімум одна цифра")
    .required("Будь ласка, придумайте пароль"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Паролі не співпадають")
    .required("Підтвердження пароля є обов'язковим"),
});

const { Text } = Typography;

const ChangePasswordForm = ({ onSubmit }) => {
  const [serverError, setServerError] = React.useState(null);

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirm: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        onSubmit({
          password: values?.password,
          password_confirmation: values?.passwordConfirm,
        })
          .then((res) => {
            setSubmitting(false);
            if (res.error) {
              const errors = res.error.data?.details;
              for (let fieldErrors in errors) {
                setFieldError(fieldErrors, errors[fieldErrors].join(", "));
              }
            }
          })
          .catch((error) => {
            setServerError(
              error?.response?.data?.errors || "Something went wrong",
            );
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form>
          <Flex vertical gap={16}>
            <Space direction="vertical" className={styles.row}>
              <div className={styles.subtitle}>Новий пароль</div>
              <Input.Password
                size={"large"}
                type="password"
                name="password"
                autoComplete="current-password"
                status={errors.password && touched.password ? "error" : ""}
                placeholder="Введіть новий пароль"
                onChange={(e) => {
                  handleChange(e);
                  setServerError(null); // Сброс ошибки сервера при изменении
                }}
                onBlur={handleBlur}
                value={values.password}
                iconRender={(visible) =>
                  visible ? <FaRegEye /> : <FaRegEyeSlash />
                }
              />
              {errors.password && touched.password ? (
                <Text type="danger">{errors.password}</Text>
              ) : null}
            </Space>

            <Space direction="vertical" className={styles.row}>
              <div className={styles.subtitle}>Підтвердження пароля</div>
              <Input.Password
                size="large"
                type="password"
                name="passwordConfirm"
                placeholder="Повторіть пароль"
                onChange={(e) => {
                  handleChange(e);
                  setServerError(null); // Сброс ошибки сервера при изменении
                }}
                onBlur={handleBlur}
                value={values.passwordConfirm}
                iconRender={(visible) =>
                  visible ? <FaRegEye /> : <FaRegEyeSlash />
                }
              />
              {errors.passwordConfirm && touched.passwordConfirm ? (
                <Text type="danger">{errors.passwordConfirm}</Text>
              ) : null}

              {serverError && <Text type="danger">{serverError}</Text>}
            </Space>
            <Flex gap={16} justify="flex-end">
              <Button
                htmlType="submit"
                className={styles.formButton}
                size="large"
                type="primary"
                disabled={isSubmitting}
              >
                Оновити пароль
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export { ChangePasswordForm };
