import { ArrowCircleDown } from "iconsax-react";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ChangePasswordForm } from "../../components/forms/change-password-form";
import { SettingsLayout } from "../../components/layouts/settings-layout";
import ROUTES from "../../routing/routes";
import { updateUserPassword } from "../../services/oauth-service";
import * as vars from "../../utils/constants/variables";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [passwordSuccessfullyChanged, setPasswordSuccessfullyChanged] =
    React.useState(false);

  const reset_token = searchParams.get("reset_token");

  const onSubmit = ({ password }) => {
    return updateUserPassword({ reset_token, password }).then(() => {
      setPasswordSuccessfullyChanged(true);
    });
  };

  useEffect(() => {
    if (!reset_token) {
      navigate(ROUTES.error);
    }
  }, [reset_token]);

  return (
    <SettingsLayout title="Налаштування">
      <div
        style={{
          height: "100%",
          width: "436px",
          margin: "0 auto",
        }}
      >
        {reset_token && !passwordSuccessfullyChanged && (
          <ChangePasswordForm onSubmit={onSubmit} />
        )}
        {passwordSuccessfullyChanged && (
          <div style={{ textAlign: "center", padding: "60px 0" }}>
            <ArrowCircleDown size="32" color={vars.colors.primaryColor} />
            <h2
              style={{
                marginTop: "20px",
              }}
            >
              Пароль успішно змінено
            </h2>
          </div>
        )}
      </div>
    </SettingsLayout>
  );
};

export { ResetPassword };
