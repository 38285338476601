import { fetchCreatePost } from "@/features/posts/posts-actions";
import routes from "@/routing/routes";
import { Button } from "antd";
import { Add } from "iconsax-react";
import { useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";

import { useGetSelfProfileState } from "../../../features/api/user-api";
import { openAuthModal } from "../../../features/auth/user-slice";
import { useModalHistory } from "../../../hooks/useModalHistory";
import * as $vars from "../../../utils/constants/variables";
import PostForm from "../../forms/post-form/post-form";
import { BottomModal } from "../../ui/modals/bottom-modal";
import styles from "./new-post-modal.module.scss";

const NewPostModal = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const isProfile = useMatch(routes.profile);
  const { open, changeState: setOpen } = useModalHistory(`newPostModal`);
  const { data: profile } = useGetSelfProfileState();

  const showModal = () => {
    if (profile?.username) {
      setOpen(true);
    } else {
      dispatch(openAuthModal());
    }
  };

  const manualCloseModal = () => {
    setOpen(false);
  };

  const onCreatePost = async ({ data }) => {
    try {
      const source = isProfile ? "profilePosts" : "feedPosts";
      return dispatch(fetchCreatePost({ body: data, source })).then((res) => {
        if (!res.payload?.errors) {
          setOpen(false);
          onSuccess();
        }
        return res;
      });
    } catch (e) {
      console.log("onCreatePost error", e);
    }
  };

  return (
    <>
      <div className={styles.newPostButton} onClick={showModal}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          icon={<Add size={20} />}
        >
          Створити пост
        </Button>
      </div>
      <BottomModal
        open={open}
        width={$vars.sizing.newPostModal}
        title={false}
        header={null}
        footer={null}
        centered={true}
        onCancel={manualCloseModal}
        closable={false}
        closeIcon={false}
        maskClosable={false}
        rootClassName={styles.newPostModal}
      >
        <PostForm onCancel={manualCloseModal} onSubmit={onCreatePost} />
      </BottomModal>
    </>
  );
};

export { NewPostModal };
