import { useEffect } from "react";

import { useCable } from "../../hooks/useCable";

const ChannelProvider = ({ children, channel, onReceived }) => {
  const cable = useCable();
  useEffect(() => {
    try {
      cable?.subscriptions?.create(
        {
          channel: channel,
        },
        {
          received: onReceived,
        },
      );
    } catch (e) {
      console.log(e);
    }
  }, [cable]);

  return children;
};

export default ChannelProvider;
