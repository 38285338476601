import styles from "./image-cut.module.scss";
import { ArrowDown2 } from "iconsax-react";
import React from "react";
import * as $vars from "@/utils/constants/variables";


const ImageCut = ({isExpanded}) => {
  return (
    <div className={styles.imageMore}>
      {isExpanded ?
        <>Згорнути</> :
        <>
          Розгорнути
          <ArrowDown2 size="16" color={$vars.colors.primaryColor} />
        </>
      }
    </div>
  )
}

export { ImageCut };