import { NOTIFICATION_TYPES } from "@/components/notifications/lib/constants";
import ROUTES from "@/routing/routes";
import React from "react";
import { generatePath } from "react-router-dom";

import { ReactComponent as BrokenHeart } from "../../../assets/icons/reactions/broken-heart.svg";
import { ReactComponent as Downvote } from "../../../assets/icons/reactions/downvote.svg";
import { ReactComponent as Heart } from "../../../assets/icons/reactions/heart.svg";
import { ReactComponent as Shit } from "../../../assets/icons/reactions/shit.svg";
import { ReactComponent as Upvote } from "../../../assets/icons/reactions/upvote.svg";
import { ReactComponent as Mention } from "../../../assets/icons/systems/mention.svg";
import { ReactComponent as MessageIcon } from "../../../assets/icons/systems/message-icon.svg";

const NOTIFICATION_MESSAGES = {
  [NOTIFICATION_TYPES.post_vote]: "Відреаговано на пост",
  [NOTIFICATION_TYPES.comment_vote]: "Відреаговано на коментар",
  [NOTIFICATION_TYPES.follow]: "Оформлено підписку",
  [NOTIFICATION_TYPES.post]: "Опубліковано пост",
  [NOTIFICATION_TYPES.post_comment]: "Залишено відповідь у пості",
  [NOTIFICATION_TYPES.thread_comment]: "Залишено відповідь у треді",
  [NOTIFICATION_TYPES.post_mention]: "Згадка у пості",
  [NOTIFICATION_TYPES.comment_mention]: "Згадка у коментарі",
  [NOTIFICATION_TYPES.ask_for_unblock]: "Надіслано запит на розблокування",
  [NOTIFICATION_TYPES.reply_to_comment]: "Залишено відповідь на коментар"
};

export const getNotificationBody = (type) =>
  NOTIFICATION_MESSAGES[type] || "Невідоме сповіщення";

export const getNotificationUrl = (type, data) => {
  const routeMap = {
    [NOTIFICATION_TYPES.post]: ROUTES.post,
    [NOTIFICATION_TYPES.thread_comment]: ROUTES.post,
    [NOTIFICATION_TYPES.post_comment]: ROUTES.post,
    [NOTIFICATION_TYPES.post_mention]: ROUTES.post,
    [NOTIFICATION_TYPES.reply_to_comment]: ROUTES.post,
    [NOTIFICATION_TYPES.comment_mention]: ROUTES.post,
    [NOTIFICATION_TYPES.comment_vote]: ROUTES.post,
    [NOTIFICATION_TYPES.post_vote]: ROUTES.post,
    [NOTIFICATION_TYPES.follow]: ROUTES.otherProfile,
    [NOTIFICATION_TYPES.ask_for_unblock]: ROUTES.otherProfile,
  };

  const route = routeMap[type];
  if (!route) return "/";

  return generatePath(route, {
    id: data?.post_id,
    username: data?.username,
    slag: data?.username,
  });
};

export const getNotificationIcon = (type, data) => {
  const voteIcons = {
    upvote: <Upvote />,
    downvote: <Downvote />,
    heart: <Heart />,
    broken: <BrokenHeart />,
    shit: <Shit />,
  };

  if (
    type === NOTIFICATION_TYPES.post_vote ||
    type === NOTIFICATION_TYPES.comment_vote
  ) {
    return voteIcons[data?.vote_type] || null;
  }

  const iconMap = {
    [NOTIFICATION_TYPES.post_mention]: <Mention />,
    [NOTIFICATION_TYPES.comment_mention]: <Mention />,
    [NOTIFICATION_TYPES.post_comment]: <MessageIcon />,
    [NOTIFICATION_TYPES.reply_to_comment]: <MessageIcon />,
    [NOTIFICATION_TYPES.thread_comment]: <MessageIcon />,
  };

  return iconMap[type] || null;
};
