import {
  useGetSelfProfileQuery,
  useGetSelfProfileState,
  useLogoutMutation,
} from "@/features/api/user-api";
import { setIsRefetchNeeded } from "@/features/posts/posts-slice";
import { load, remove } from "@/utils/helpers/local-storage";
import { Dropdown } from "antd";
import { useDispatch } from "react-redux";
import {
  Link,
  createSearchParams,
  useMatch,
  useNavigate,
} from "react-router-dom";

import ROUTES from "../../../routing/routes";
import { Notifications } from "../../notifications/notifications";
import { Avatar } from "../avatar";
import { LogoHeader } from "../logo/logo-header";
import { LoginModal } from "../modals/login-modal";
import { headerMenuActions, headerMenuItems } from "./constants";
import styles from "./header.module.scss";
import { HeaderSearch } from "./search/search";
import { Trigger } from "./trigger/trigger";

const Header = ({ onToggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isProfilePage = useMatch(ROUTES.profile);
  useGetSelfProfileQuery(undefined, {
    skip: !load("accessToken"),
  });
  const { data: profile } = useGetSelfProfileState();
  const [logoutTrigger] = useLogoutMutation();

  const onMenuItemClick = async ({ key }) => {
    switch (key) {
      case headerMenuActions.logout.key: {
        remove("accessToken");
        remove("refreshToken");
        await logoutTrigger();
        if (isProfilePage) navigate(ROUTES.home);
      }
    }
  };

  const onLogoClick = () => {
    dispatch(
      setIsRefetchNeeded({
        source: "feedPosts",
        value: true,
      }),
    );
  };
  const profile_avatar = profile?.profile_image?.small?.url;

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.logoContainer}>
          <Trigger onClick={onToggleSidebar} />
          <Link
            to={{
              pathname: ROUTES.feed,
              search: createSearchParams({ type: "fresh" }).toString(),
            }}
            onClick={onLogoClick}
          >
            <LogoHeader />
          </Link>
        </div>
        <div className={styles.controlsContainer}>
          <>
            <HeaderSearch />
            {profile?.username && (
              <div className={styles.controlsIcons}>
                <Notifications />

                <Dropdown
                  className={styles.profileButton}
                  menu={{
                    items: headerMenuItems,
                    onClick: onMenuItemClick,
                  }}
                  trigger={["click"]}
                >
                  <span>
                    <Avatar
                      profile_avatar={profile_avatar}
                      className={styles.headerAvatar}
                    />
                  </span>
                </Dropdown>
              </div>
            )}
          </>

          {!profile?.username && (
            <div className={styles.signInContainer}>
              <LoginModal />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
