import { Button, Flex, Input, Space } from "antd";
import { Formik } from "formik";
import React from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import * as Yup from "yup";

import FormError from "../form-error";
import styles from "./login-form.module.scss";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = ({ onSubmit, onChangeType }) => {
  const [serverError, setServerError] = React.useState(null);
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setServerError("");
          onSubmit(values)
            .catch((err) => {
              setServerError(err?.errors || "Something went wrong");
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Flex vertical gap={16}>
            <Space direction="vertical">
              <Input
                size={"large"}
                type="email"
                name="email"
                autoComplete="username"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                status={errors.email && touched.email ? "error" : ""}
              />
            </Space>
            <Space direction="vertical">
              <Input.Password
                size={"large"}
                type="password"
                name="password"
                autoComplete="current-password"
                status={errors.password && touched.password ? "error" : ""}
                placeholder="Пароль"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                iconRender={(visible) =>
                  visible ? <FaRegEye /> : <FaRegEyeSlash />
                }
              />
              {serverError?.length > 0 &&
                serverError?.map((error) => (
                  <FormError key={error}>{error}</FormError>
                ))}
            </Space>
            <Button
              className={styles.formButton}
              size={"large"}
              type={"primary"}
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting || Boolean(Object.keys(errors).length)}
            >
              Далі
            </Button>
            <div
              className={styles.styledLink}
              onClick={() => onChangeType("restore")}
            >
              Забули пароль?
            </div>
          </Flex>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
