import { useGetOtherProfileQuery } from "@/features/api/user-api";
import ROUTES from "@/routing/routes";
import { Flex } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React from "react";
import { Link, generatePath } from "react-router-dom";

import { useGetSelfProfileState } from "../../../features/api/user-api";
import { OtherProfileActionsButton } from "../../profile/other-profile-actions-button";
import { ProfileBackdrop } from "../../profile/profile-header/components/profile-backdrop";
import { Avatar } from "../../ui/avatar";
import { Spinner } from "../../ui/spinner";
import styles from "./popover-user.module.scss";

const PopoverUser = ({ slug }) => {
  const { data: profile, isFetching } = useGetOtherProfileQuery(slug);
  const { data: selfProfile } = useGetSelfProfileState();
  const profileAvatar = profile?.profile_image?.medium?.url;
  const coverImage = profile?.cover_image?.large?.url;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {isFetching && <Spinner />}
      {!isFetching && profile && (
        <div className={styles.popover}>
          <ProfileBackdrop height={103} profileBg={coverImage} />
          <Flex gap={8} className={styles.popoverContent}>
            <Link
              to={generatePath(ROUTES.otherProfile, {
                slag: profile?.username,
              })}
            >
              <Avatar
                profile_avatar={profileAvatar}
                className={styles.avatar}
              />
            </Link>
            <Flex vertical>
              <Link
                to={generatePath(ROUTES.otherProfile, {
                  slag: profile?.username,
                })}
              >
                <div className={styles.popoverName}>
                  {profile?.display_name}
                </div>
              </Link>
              <div className={styles.popoverUsername}>@{profile?.username}</div>
            </Flex>
          </Flex>
          <div className={styles.popoverBio}>
            {profile?.bio && (
              <Paragraph
                className={styles.popoverBioText}
                ellipsis={{ rows: 3 }}
              >
                {profile?.bio}
              </Paragraph>
            )}
            {selfProfile?.username !== profile?.username && (
              <div className={styles.popoverButtons}>
                <OtherProfileActionsButton
                  username={profile?.username}
                  relation={profile?.relations}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopoverUser;
