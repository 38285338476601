import ROUTES from "@/routing/routes";
import { formatDateTemplate } from "@/utils/helpers/date";
import { Flex, Typography } from "antd";
import { format } from "date-fns";
import { ArrowRight } from "iconsax-react";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, generatePath } from "react-router-dom";

import { useGetSelfProfileState } from "../../../features/api/user-api";
import { openAuthModal } from "../../../features/auth/user-slice";
import cn from "../../../utils/helpers/className";
import { FormattedMetadata } from "../../formatted-metadata";
import PostImages from "../../post/post-images/post-images";
import { Avatar } from "../../ui/avatar";
import { Spinner } from "../../ui/spinner";
import { ProfileInfoTooltip } from "../../user-popover";
import CommentsActions from "../comments-actions";
import { COMMENTS_ACTION_TYPES } from "../constants";
import { EditComment } from "../edit-comment";
import { WriteComment } from "../write-comment";
import styles from "./thread-comment.module.scss";

const ThreadComment = ({
  comment,
  parentId = null,
  isLast = false,
  onRemove,
  onVote,
  onReply,
}) => {
  const dispatch = useDispatch();
  const {
    id,
    class: comment_class,
    external_images = [],
    body = "",
    metadata = {},
    votes_up = 0,
    votes_down = 0,
    reposts_count = 0,
    comments_count = 0,
    profile: { display_name = "", username = "", profile_image = {} } = {},
    published_at_str = "",
    isLoading = false,
    published_at = null,
    error = null,
    vote = {},
    reply_to = null,
    permissions = {},
    post_id,
  } = comment;

  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { data: selfProfile } = useGetSelfProfileState();

  const isRemoved = comment_class === "Placeholder";

  const profile_avatar = profile_image?.small?.url;

  const handleVote = (type) => {
    onVote(comment?.id, type, parentId);
  };

  const onEditStart = () => {
    setIsEditing(true);
  };

  const onReportStart = () => {
    console.log("onReportStart");
  };

  const onCommentClick = () => {
    if (!isCommentFormOpen) {
      selfProfile?.username
        ? setIsCommentFormOpen(true)
        : dispatch(openAuthModal());
    }
    if (selfProfile?.username) {
      setIsCommentFormOpen(!isCommentFormOpen);
    }
  };

  const moreDotsActionsHandlers = {
    [COMMENTS_ACTION_TYPES.REMOVE]: () => onRemove(comment.id, parentId),
    [COMMENTS_ACTION_TYPES.EDIT]: onEditStart,
    [COMMENTS_ACTION_TYPES.REPORT]: onReportStart,
  };

  const toolbarActionHandlers = {
    [COMMENTS_ACTION_TYPES.COMMENT]: onCommentClick,
  };

  const onToolbarActionClick = (type) => {
    toolbarActionHandlers[type]();
  };

  const onMoreDotsSelect = (type) => {
    moreDotsActionsHandlers[type]();
  };

  const onReplyRequest = async (body) => {
    const res = await onReply(comment.id, body);
    if (res?.payload?.id) {
      setIsCommentFormOpen(false);
    }
  };

  return (
    <div className={styles.threadCommentWrap}>
      {!isLast && <div className={styles.secondLevelLine} />}
      <Flex
        className={cn(styles.threadCommentBox, isRemoved && styles.removed)}
        gap={8}
      >
        {isRemoved && <div className={styles.removedDot} />}
        {!isRemoved && (
          <Link to={generatePath(ROUTES.otherProfile, { slag: username })}>
            <ProfileInfoTooltip username={username}>
              <div>
                <Avatar
                  profile_avatar={profile_avatar}
                  className={styles.commentAvatar}
                />
              </div>
            </ProfileInfoTooltip>
          </Link>
        )}

        <Flex vertical gap={2} flex={1}>
          {!isRemoved && (
            <Flex className={styles.commentHeader} align={"center"} gap={6}>
              <Link
                className={styles.commentAuthor}
                to={generatePath(ROUTES.otherProfile, { slag: username })}
              >
                <ProfileInfoTooltip username={username}>
                  {display_name}
                </ProfileInfoTooltip>
              </Link>
              <span className={styles.commentDelimiter}>·</span>
              <Link
                className={styles.commentDateTime}
                to={generatePath(ROUTES.otherProfile, { slag: username })}
                title={
                  published_at?.length &&
                  format(published_at, formatDateTemplate)
                }
              >
                {published_at_str}
              </Link>
              {reply_to && (
                <>
                  <ArrowRight size="13" color="#ccc" />
                  {reply_to?.profile?.display_name && (
                    <Link className={styles.commentReplyTo} to={"#"}>
                      <ProfileInfoTooltip
                        username={reply_to?.profile?.username}
                      >
                        {reply_to?.profile?.display_name}
                      </ProfileInfoTooltip>
                    </Link>
                  )}
                  {reply_to?.class === "Placeholder" && (
                    <div className={styles.commentReplyToHidden}>
                      Прихований коментар
                    </div>
                  )}
                </>
              )}
            </Flex>
          )}

          <div className={styles.commentBody}>
            {isRemoved && (
              <span className={styles.commentHiddenComment}>
                Прихований коментар
              </span>
            )}
            {!isRemoved && !isEditing && (
              <>
                <FormattedMetadata text={body} metadata={metadata} />
                {external_images?.length > 0 && (
                  <PostImages
                    isComment={true}
                    metadata={metadata}
                    images={external_images}
                    post={{
                      body,
                      id: post_id,
                      profile: { display_name, profile_image, username },
                      published_at_str,
                      published_at,
                    }}
                  />
                )}
              </>
            )}
            {isEditing && (
              <div className={styles.commentForm}>
                <EditComment
                  commentId={id}
                  postId={post_id}
                  onCancel={() => setIsEditing(false)}
                />
              </div>
            )}
          </div>
          {!isRemoved && (
            <div className={styles.commentActions}>
              <CommentsActions
                onToolbarActionClick={onToolbarActionClick}
                onMoreDotsSelect={onMoreDotsSelect}
                permissions={permissions}
                votesCount={vote?.vote_sum}
                activeVote={vote?.vote_type}
                onVote={handleVote}
                counters={{
                  id,
                  votes_up,
                  votes_down,
                  reposts_count,
                  comments_count,
                }}
              />
            </div>
          )}

          {isCommentFormOpen && (
            <WriteComment defaultOpen autoFocus onSubmit={onReplyRequest} />
          )}
        </Flex>
      </Flex>
      {isLoading && <Spinner />}
      {error && <Typography.Text>{error}</Typography.Text>}
    </div>
  );
};

const memoizedThread = memo(ThreadComment);

export { memoizedThread as ThreadComment };
