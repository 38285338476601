import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { NotificationsList } from "../notifications-list";
import { Flex } from "antd";

const ScrollableNotificationsList = ({
  notifications,
  fetchNext,
  hasMore,
  onClose,
}) => {
  return (
    <InfiniteScroll
      initialLoad={false}
      loadMore={fetchNext}
      hasMore={hasMore}
      useWindow={false}
      threshold={400}
    >
      <Flex vertical gap={4}>
        <NotificationsList
          notifications={notifications}
          handleCloseDrawer={onClose}
        />
      </Flex>
    </InfiniteScroll>
  );
};

export { ScrollableNotificationsList };
