import { useDispatch } from "react-redux";

import { api } from "../../features/api/api";
import ChannelProvider from "./channel-provider";

const ProfileUpdateProvider = ({ children }) => {
  const dispatch = useDispatch();
  const onReceived = (data) => {
    switch (data.event) {
      case "updated":
        dispatch(
          api.util.updateQueryData(
            "getSelfProfile",
            undefined,
            (draftProfile) => {
              draftProfile.flags.is_new_events = data.is_new_events;
            },
          ),
        );
        break;
      default:
        break;
    }
  };

  return (
    <ChannelProvider channel={"ProfileChannel"} onReceived={onReceived}>
      {children}
    </ChannelProvider>
  );
};

export default ProfileUpdateProvider;
