import React from "react";

const SignupSuccess = () => {
  return (
    <div>
      <p>
        На вашу електронну адресу надійде лист із посиланням на завершення
        реєстрації.
      </p>
      <p>Перейдіть за цим посиланням, щоб активувати профіль.</p>
      <p>
        Якщо листа не буде в папці &#34;Вхідні&#34;, перевірте папку
        &#34;Спам&#34;.
      </p>
    </div>
  );
};

export { SignupSuccess };
