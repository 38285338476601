import { Spinner } from "@/components/ui/spinner";
import React from "react";

import useNotifications from "../../../hooks/use-notifications";
import { ScrollableNotificationsList } from "../../ui/notifications/scrollable-notifications-list";

const UserNotifications = ({ onClose }) => {
  const {
    data: notifications,
    fetchNext,
    hasMore,
    isLoading: isNotificationsLoading,
  } = useNotifications({
    open,
  });

  return (
    <>
      <ScrollableNotificationsList
        fetchNext={fetchNext}
        notifications={notifications}
        hasMore={hasMore}
        onClose={onClose}
      />
      {isNotificationsLoading && <Spinner />}
    </>
  );
};

export { UserNotifications };
