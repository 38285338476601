import { Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { useGetSelfProfileState } from "../../../features/api/user-api";
import { openAuthModal } from "../../../features/auth/user-slice";
import { CommentForm } from "../../forms/comment-form";
import styles from "../../forms/comment-form/comment-form.module.scss";
import cn from "../../../utils/helpers/className";

const WriteComment = ({ autoFocus, onSubmit, defaultOpen, noMargin = false }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const { data: profile } = useGetSelfProfileState();

  const openCommentModal = () => {
    if (profile?.username) {
      setIsOpen(true);
    } else {
      dispatch(openAuthModal());
    }
  };

  return (
    <>
      {!defaultOpen && !isOpen && (
        <Input.TextArea
          height={47}
          onClick={openCommentModal}
          size={"middle"}
          autoSize={true}
          value={""}
          autoFocus={autoFocus}
          className={styles.commentForm}
          placeholder="Написати коментар"
        />
      )}
      {isOpen && (
        <div className={cn(
          styles.desktopCommentWrapper,
          noMargin && styles.noMargin,
        )}>
          <CommentForm
            noMargin={noMargin}
            onSubmit={onSubmit}
            onCancel={() => setIsOpen(false)}
            isEditing={false}
          />
        </div>
      )}
    </>
  );
};

export {WriteComment};
