import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import { SettingsLayout } from "../../components/layouts/settings-layout";
import { SettingsMenu } from "../../components/layouts/settings-layout/shared/ui/settings-menu";
import styles from "./settings.module.scss";

const Settings = () => {
  const location = useLocation();
  const type = location.pathname.split("/")[2];

  return (
    <SettingsLayout title="Налаштування" sidebar={<SettingsMenu />}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          {!type && (
            <div className={styles.notSelectedType}>
              Оберіть тип налаштувань
            </div>
          )}
          <Outlet />
        </div>
      </div>
    </SettingsLayout>
  );
};

export { Settings };
