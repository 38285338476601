import { Button, Flex, Typography } from "antd";
import { ArrowCircleDown } from "iconsax-react";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SettingsLayout } from "../../components/layouts/settings-layout";
import { Spinner } from "../../components/ui/spinner";
import { useConfirmEmailMutation } from "../../features/api/user-api";
import ROUTES from "../../routing/routes";
import * as vars from "../../utils/constants/variables";
import { load } from "../../utils/helpers/local-storage";
import styles from "./email-confirm.module.scss";

const EmailConfirm = () => {
  const [confirmRequest, { isLoading, error }] = useConfirmEmailMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleConfirm = async (token) => {
    try {
      if (load("accessToken")) {
        setTimeout(() => navigate(ROUTES.home), 3000);
        return;
      }
      const res = await confirmRequest(token);
      if (res?.data?.username) {
        navigate(ROUTES.home);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (token) {
      handleConfirm(token);
    }
    if (!token) {
      navigate(ROUTES.error);
    }
  }, [token, navigate]);

  return (
    <SettingsLayout>
      <div className={styles.wrapper}>
        {isLoading && (
          <Flex gap={"8px"} vertical align={"center"}>
            <Spinner />
            <Typography.Paragraph>
              Відбувається підтвердження електронної пошти
            </Typography.Paragraph>
          </Flex>
        )}
        {!isLoading && !error && (
          <Flex gap={"8px"} vertical align={"center"}>
            <ArrowCircleDown size="32" color={vars.colors.primaryColor} />
            <br />
            <Typography.Paragraph>
              Електронна пошта підтверджена
            </Typography.Paragraph>
            <Button
              type="primary"
              size="large"
              onClick={() => navigate(ROUTES.home)}
            >
              На головну
            </Button>
          </Flex>
        )}
        {!isLoading && error?.data?.errors && (
          <Typography.Paragraph type={"danger"}>
            {error?.data?.errors?.join(" ")}
          </Typography.Paragraph>
        )}
      </div>
    </SettingsLayout>
  );
};

export { EmailConfirm };
