import { Button, Flex, Input, Space, Typography } from "antd";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import styles from "../create-account/create-account.module.scss";

const { Text } = Typography;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Це обов'язкове поле")
    .email("Невірний формат email"),
});

const RemindForm = ({ onSubmit, error }) => {
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false); // Разблокировать кнопку после submit
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Flex vertical gap={16}>
          <Space direction="vertical">
            <Input
              size="large"
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {errors.email && touched.email && (
              <Text type="danger">{errors.email}</Text>
            )}
          </Space>
          {error && (
            <Text type="danger">
              {error?.response?.data?.errors[0] || "Помилка"}
            </Text>
          )}
          <Button
            className={styles.formButton}
            size="large"
            type="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Відновити пароль
          </Button>
        </Flex>
      )}
    </Formik>
  );
};

export default RemindForm;
