import React, { useState } from "react";

import * as vars from "../../../utils/constants/variables";

const DragAndDropWrapper = ({ onDropFile, children }) => {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => setDragOver(false);

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    Array.from(e.dataTransfer.files).forEach(onDropFile);
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        height: "100%",
        border: dragOver
          ? `2px dashed ${vars.colors.primary}`
          : "2px dashed transparent",
        backgroundColor: dragOver ? "#f0f9ff" : "transparent",
        transition: "border 0.2s ease, background-color 0.2s ease",
      }}
    >
      {children}
    </div>
  );
};

export { DragAndDropWrapper };
