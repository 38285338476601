export const SETTINGS_MENU_ITEMS = [
  {
    key: "profile",
    title: "Профіль",
  },
  {
    key: "account",
    title: "Акаунт",
  },
  {
    key: "content",
    title: "Контент",
    disabled: true,
  },
  {
    key: "black-list",
    title: "Чорний список",
    disabled: true,
  },
];
