import React from "react";

import styles from "./settings-item-container.module.scss";

const SettingsItemContainer = ({ title, children }) => {
  return (
    <div className={styles.wrapper}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export { SettingsItemContainer };
