import React from "react";

const RestoreSuccess = ({ onResendClick }) => {
  return (
    <div>
      <p>
        На вашу електронну адресу надійде лист із посиланням на зміну паролю.
      </p>
      <p>Перейдіть за цим посиланням, щоб змінити пароль.</p>
      <p>
        Якщо листа не буде в папці &#34;Вхідні&#34;, перевірте папку
        &#34;Спам&#34;.
      </p>
      <br />
      <div>
        Не прийшов лист?{" "}
        <a
          onClick={(e) => {
            e.preventDefault();
            onResendClick();
          }}
        >
          Відправити ще раз
        </a>
      </div>
    </div>
  );
};

export { RestoreSuccess };
