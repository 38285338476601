import { Button, Modal } from "antd";
import { Youtube } from "iconsax-react";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import { useModalHistory } from "../../../../hooks/useModalHistory";
import styles from "./link-mobile-preview.module.scss";

export const LinkMobilePreview = ({
  url,
  title,
  description,
  external_image,
  type,
  label,
  hasPreview,
  videoId,
}) => {
  const { open, changeState: setOpen } = useModalHistory(`modalPreview-${url}`);

  const showModal = () => {
    if (hasPreview) {
      setOpen(true);
    }
  };

  const manualCloseModal = () => {
    setOpen(false);
  };

  const handleCancel = useCallback(() => {
    setOpen(null);
  }, []);

  return (
    <>
      <span className={styles.link} onClick={showModal}>
        {type === "youtube_video" && (
          <span className={styles.linkImg}>
            <Youtube size="18" color="#FF8A65" variant="Bold" />
          </span>
        )}
        {label}
      </span>
      <Modal
        destroyOnClose
        title={null}
        footer={null}
        open={open}
        onCancel={manualCloseModal}
        className={styles.linkPreviewModal}
        width={"100%"}
        transitionName=""
        closable={false}
      >
        {external_image && !videoId && (
          <div className={styles.modalImage}>
            <img src={external_image} alt={title} />
          </div>
        )}
        {videoId && (
          <div className={styles.modalVideo}>
            <iframe
              frameBorder="0"
              className={styles.youtubeIframe}
              src={`https://www.youtube.com/embed/${videoId}`}
              allowFullScreen="allowfullscreen"
            />
          </div>
        )}
        <div className={styles.modalContent}>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalDescription}>{description}</div>
        </div>
        <div className={styles.modalControls}>
          <Button
            size="large"
            className={styles.modalButton}
            onClick={manualCloseModal}
          >
            Назад
          </Button>
          <Button
            as={Link}
            type="primary"
            size="large"
            className={styles.modalButton}
            href={url}
            target="_blank"
            onClick={handleCancel}
          >
            Перейти
          </Button>
        </div>
      </Modal>
    </>
  );
};
