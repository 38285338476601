import { useMatch } from "react-router-dom";

import ROUTES from "../routing/routes";

const useMatchWithRoutesCheck = (route) => {
  const isOtherProfilePage = useMatch(route);
  if (
    Object.values(ROUTES).concat("/feed").includes(isOtherProfilePage?.pathname)
  ) {
    return null;
  } else {
    return isOtherProfilePage;
  }
};

export default useMatchWithRoutesCheck;
