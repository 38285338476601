import {
  GALLERY_ANIMATION_SPEED,
  GALLERY_SLIDES_TO_SCROLL,
  GALLERY_SLIDES_TO_SHOW,
} from "../../../../utils/constants/integers";
import cn from "@/utils/helpers/className";
import React, { useEffect, useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import Slider from "react-slick";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import styles from "./image-modal.module.scss";

const Arrow = ({ direction, onClick }) => (
  <div
    className={
      direction === "prev" ? styles.modalThumbsPrev : styles.modalThumbsNext
    }
    onClick={onClick}
  >
    {direction === "prev" ? (
      <FaAngleLeft size="24" color="white" />
    ) : (
      <FaAngleRight size="24" color="white" />
    )}
  </div>
);

const ImageModal = ({ images, onNavigation, startFromIndex, infoSection }) => {
  const [zoomed, setZoomed] = useState(false);
  const [currentImage, setCurrentImage] = useState(startFromIndex);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") onNavigation(null);
      else if (event.key === "ArrowLeft") sliderRef1.current?.slickPrev();
      else if (event.key === "ArrowRight") sliderRef1.current?.slickNext();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onNavigation]);

  useEffect(() => setZoomed(false), [currentImage]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "revert-layer");
  }, []);

  const isMultipleImages = images?.length > 1;

  return (
    <div
      id="modal"
      className={styles.modal}
      onClick={(e) => e.target.id === "modal" && onNavigation(null)}
    >
      <div className={styles.modalHeader}>
        {infoSection}
        <div className={styles.modalClose} onClick={() => onNavigation(null)}>
          <MdClose size="32" color="white" />
        </div>
      </div>
      <div className={styles.modalBody}>
        {isMultipleImages && (
          <div
            className={styles.navigationButton}
            style={{ left: "0" }}
            onClick={() => sliderRef1.current.slickPrev()}
          >
            <FaAngleLeft size="32" color={"white"} />
          </div>
        )}
        <div className={styles.sliderWrapper}>
          <Slider
            swipe={!zoomed}
            className={zoomed && styles.slick}
            initialSlide={startFromIndex}
            beforeChange={(_, next) => setCurrentImage(next)}
            asNavFor={sliderRef2.current}
            arrows={false}
            ref={sliderRef1}
            fade
            speed={GALLERY_ANIMATION_SPEED}
          >
            {images?.map((image) => (
              <div key={image?.large?.url}>
                <div
                  className={styles.modalBlur}
                  style={{ backgroundImage: `url(${image?.large?.url})` }}
                />
                <TransformWrapper
                  onZoomStop={(ref) => setZoomed(ref.state.scale > 1)}
                  panning={{ disabled: !zoomed }}
                  doubleClick={{ mode: "toggle" }}
                >
                  <TransformComponent
                    contentClass={styles.modalImageWrap}
                    wrapperStyle={{ width: "100%" }}
                  >
                    <img src={image?.large?.url} alt="" />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            ))}
          </Slider>
        </div>
        {isMultipleImages && (
          <div
            className={styles.navigationButton}
            style={{ right: "0" }}
            onClick={() => sliderRef1.current.slickNext()}
          >
            <FaAngleRight size="32" color={"white"} />
          </div>
        )}
      </div>
      {isMultipleImages && (
        <div className={styles.modalFooter}>
          <div className={styles.modalControls}>
            <div className={styles.modalCounter}>
              {currentImage + 1} з {images.length}
            </div>
            <div className={styles.modalThumbs}>
              <Arrow
                direction="prev"
                onClick={() => sliderRef1.current.slickPrev()}
              />
              <div className={styles.modalThumbsList}>
                <div className={cn(styles.previewWrapper, "slider-container")}>
                  <Slider
                    swipe={!zoomed}
                    asNavFor={sliderRef1.current}
                    ref={sliderRef2}
                    slidesToShow={Math.min(
                      images.length,
                      GALLERY_SLIDES_TO_SHOW,
                    )}
                    slidesToScroll={GALLERY_SLIDES_TO_SCROLL}
                    focusOnSelect
                    centerMode={images.length <= GALLERY_SLIDES_TO_SHOW}
                    centerPadding={0}
                    arrows={false}
                    className="thumbs-slider"
                    speed={0}
                    variableWidth
                    infinite={images.length > GALLERY_SLIDES_TO_SHOW}
                  >
                    {images.map((image) => (
                      <div
                        className={styles.previewImageWrapper}
                        key={image?.small?.url}
                      >
                        <img src={image?.small?.url} alt="" />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              <Arrow
                direction="next"
                onClick={() => sliderRef1.current.slickNext()}
              />
            </div>
            <div className={styles.modalCounterEmpty} />
          </div>
        </div>
      )}
    </div>
  );
};

export { ImageModal };
