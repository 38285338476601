import { useLocation, useNavigate } from "react-router-dom";

export const useModalHistory = (modalName) => {
  const navigate = useNavigate();
  const location = useLocation();
  const open = location.state?.[modalName];

  const changeState = (state, historyBack = false) => {
    if (!state) {
      navigate(
        historyBack
          ? -1
          : {
              pathname: location.pathname,
              search: location.search,
              preventScrollReset: true,
            },
        {
          preventScrollReset: true,
          state: {
            [modalName]: state,
          },
        },
      );
    } else {
      navigate(
        {
          pathname: location.pathname,
          search: location.search,
        },
        {
          preventScrollReset: true,
          state: {
            [modalName]: state,
          },
        },
      );
    }
  };

  return {
    open,
    changeState,
  };
};
