import PropTypes from "prop-types";
import React from "react";

import {
  useGetOtherProfileAttachmentsQuery,
  useGetOtherProfileQuery,
} from "../../../features/api/user-api";
import ModalImageUserInfo from "../../ui/modal-image-user-info";
import { ImageModal } from "../../ui/modals/image-modal";

const FullscreenAvatars = ({ onAttachmentEvent, username }) => {
  const { data: profileData } = useGetOtherProfileQuery(username, {
    skip: !username,
  });

  const { data: profileAttachments } = useGetOtherProfileAttachmentsQuery(
    {
      username: username,
      attachment_type: "profile_image",
    },
    {
      skip: !username,
    },
  );

  return (
    <ImageModal
      infoSection={
        <ModalImageUserInfo
          body={null}
          username={username}
          name={profileData?.display_name}
          avatar={profileData?.profile_image?.small?.url}
          date={null}
        />
      }
      images={profileAttachments?.attachments || []}
      startFromIndex={0}
      onNavigation={onAttachmentEvent}
    />
  );
};

FullscreenAvatars.propTypes = {
  onAttachmentEvent: PropTypes.func,
  username: PropTypes.number,
};

export { FullscreenAvatars };
