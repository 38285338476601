import { Drawer } from "antd";
import { isMobile } from "react-device-detect";

import {
  NOTIFICATIONS_ASIDE_HEIGHT,
  NOTIFICATIONS_ASIDE_WIDTH,
} from "../../../utils/constants/integers";
import styles from "../notifications.module.scss";
import { UserNotifications } from "../user-notifications";

const NotificationsDrawer = ({ onClose, open }) => {
  return (
    <Drawer
      title={"Сповіщення"}
      onClose={onClose}
      open={open}
      width={NOTIFICATIONS_ASIDE_WIDTH}
      height={NOTIFICATIONS_ASIDE_HEIGHT}
      extra={true}
      className={styles.drawer}
      rootClassName={styles.drawerContainer}
      classNames={{
        content: styles.drawerWrapper,
        header: styles.drawerHeader,
      }}
      placement={isMobile ? "bottom" : "right"}
    >
      {open && <UserNotifications onClose={onClose} />}
    </Drawer>
  );
};

export { NotificationsDrawer };
