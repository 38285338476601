import ROUTES from "@/routing/routes";
import cn from "@/utils/helpers/className";
import { formatDateTemplate } from "@/utils/helpers/date";
import { Flex } from "antd";
import { format } from "date-fns";
import { AddSquare, MinusSquare } from "iconsax-react";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, generatePath } from "react-router-dom";

import { useGetSelfProfileState } from "../../../features/api/user-api";
import { openAuthModal } from "../../../features/auth/user-slice";
import { FormattedMetadata } from "../../formatted-metadata";
import PostImages from "../../post/post-images/post-images";
import { Avatar } from "../../ui/avatar";
import { Spinner } from "../../ui/spinner";
import { ProfileInfoTooltip } from "../../user-popover";
import CommentsActions from "../comments-actions";
import {
  COMMENTS_ACTION_TYPES,
  sortArrayWithPostDataToEnd,
} from "../constants";
import { EditComment } from "../edit-comment";
import ThreadComment from "../thread-comment";
import { WriteComment } from "../write-comment";
import styles from "./thread-item.module.scss";

const ThreadItem = ({
  comment,
  onRemove,
  onVote,
  onReply,
  handleLoadNextThreadComments,
}) => {
  const dispatch = useDispatch();
  const {
    id,
    body,
    external_images,
    metadata,
    votes_up,
    votes_down,
    reposts_count,
    comments_count,
    profile,
    published_at_str,
    comments,
    isLoading,
    comment_last_id,
    comments_left,
    error,
    published_at,
    vote,
    permissions,
    post_id,
  } = comment;
  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);
  const { data: selfProfile } = useGetSelfProfileState();

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const onVoteClick = (type) => {
    onVote(comment.id, type);
  };

  const onRemoveConfirm = () => {
    onRemove(comment.id);
  };

  const onCommentClick = () => {
    if (!isCommentFormOpen) {
      selfProfile?.username ? setIsExpanded(true) : dispatch(openAuthModal());
    }
    if (selfProfile?.username) {
      setIsCommentFormOpen(!isCommentFormOpen);
    }
  };

  const onEditStart = () => {
    setIsEditing(true);
  };

  const onReportStart = () => {
    console.log("onReportStart");
  };

  const onReplyRequest = async (body) => {
    const res = await onReply(comment.id, body);
    if (res?.payload?.id) {
      setIsCommentFormOpen(false);
    }
  };

  const onMoreDotsSelect = (type) => {
    moreDotsActionsHandlers[type]();
  };

  const onToolbarActionClick = (type) => {
    toolbarActionHandlers[type]();
  };

  const moreDotsActionsHandlers = {
    [COMMENTS_ACTION_TYPES.REMOVE]: onRemoveConfirm,
    [COMMENTS_ACTION_TYPES.EDIT]: onEditStart,
    [COMMENTS_ACTION_TYPES.REPORT]: onReportStart,
  };

  const toolbarActionHandlers = {
    [COMMENTS_ACTION_TYPES.COMMENT]: onCommentClick,
  };

  const profile_avatar = profile?.profile_image?.small?.url;

  const profile_active = profile?.display_name;

  return (
    <Flex className={styles.threadWrap} vertical gap={12}>
      <div className={styles.firstWrap}>
        {comments?.length > 0 && (
          <>
            <div
              className={cn(
                styles.firstLevelLine,
                isExpanded ? "isExpanded" : "",
              )}
            />
            <div
              className={cn(styles.btnCollapse, isExpanded ? "isExpanded" : "")}
              onClick={handleExpand}
            >
              {isExpanded ? (
                <MinusSquare size="20" color="#91919d" />
              ) : (
                <AddSquare size="20" color="#91919d" />
              )}
            </div>
          </>
        )}
        <Flex gap={8}>
          {profile_active ?
            <Link
              to={
                profile?.username
                  ? generatePath(ROUTES.otherProfile, {
                      slag: profile?.username,
                    })
                  : ""
              }
            >
              <ProfileInfoTooltip username={profile?.username}>
                <div>
                  <Avatar
                    profile_avatar={profile_avatar}
                    className={styles.commentAvatar}
                  />
                </div>
              </ProfileInfoTooltip>
            </Link> :
            <Avatar
              profile_avatar={profile_avatar}
              className={styles.commentAvatar}
            />
          }



          <Flex vertical gap={2} flex={1}>
            <Flex className={styles.commentHeader} align={"center"} gap={6}>
              {profile_active &&
                <>
                <Link
                  className={styles.commentAuthor}
                  to={generatePath(ROUTES.otherProfile, {
                    slag: profile?.username || "",
                  })}
                >
                  <ProfileInfoTooltip username={profile?.username}>
                    {profile?.display_name}
                  </ProfileInfoTooltip>
                </Link>
                <span className={styles.commentDelimiter}>·</span>
                <Link
                    className={styles.commentDateTime}
                  to={generatePath(ROUTES.otherProfile, {
                    slag: profile?.username || "",
                  })}
                  title={
                    published_at?.length &&
                    format(published_at, formatDateTemplate)
                  }
                >
                  {published_at_str}
                </Link>
                </>
              }
            </Flex>
            {isEditing && (
              <div className={styles.commentForm}>
                <EditComment
                  commentId={id}
                  postId={post_id}
                  onCancel={() => setIsEditing(false)}
                />
              </div>
            )}

            {!isEditing && (
              <div className={styles.commentBody}>
                {comment.class === "Placeholder" && (
                  <div className={styles.deleted}>Прихований комментар</div>
                )}
                <FormattedMetadata text={body} metadata={metadata} />
                {external_images?.length > 0 && (
                  <PostImages
                    isComment={true}
                    metadata={metadata}
                    images={external_images}
                    post={{
                      body,
                      id,
                      profile: {
                        username: profile?.user,
                        display_name: profile?.display_name || "",
                        profile_image: profile?.image || null,
                      },
                      published_at_str,
                      published_at,
                    }}
                  />
                )}
              </div>
            )}

            <div className={cn(
              styles.commentActions,
              !profile_active && styles.disabledActions,
            )}>
              <CommentsActions
                permissions={permissions}
                onVote={onVoteClick}
                onToolbarActionClick={onToolbarActionClick}
                onMoreDotsSelect={onMoreDotsSelect}
                votesCount={vote?.vote_sum || 0}
                activeVote={vote?.vote_type}
                counters={{
                  id,
                  votes_up,
                  votes_down,
                  reposts_count,
                  comments_count,
                }}
              />
            </div>

            {isCommentFormOpen && (
              <WriteComment defaultOpen autoFocus onSubmit={onReplyRequest} />
            )}
          </Flex>
        </Flex>
      </div>
      {comments?.length > 0 && isExpanded && (
        <div className={styles.threadCommentsWrap}>
          <Flex vertical gap={12}>
            {sortArrayWithPostDataToEnd(comments).map((comment, index) => (
              <ThreadComment
                onRemove={onRemove}
                onVote={onVote}
                onReply={onReply}
                parentId={id}
                key={comment.id}
                comment={comment}
                isLast={comments_left === 0 && index === comments?.length - 1}
              />
            ))}
          </Flex>
        </div>
      )}
      {isLoading && (
        <div style={{ marginTop: "16px" }}>
          <Spinner />
        </div>
      )}
      {!isLoading && isExpanded && comments_left > 0 && (
        <Flex className={styles.moreWrap} align="center">
          <div
            className={styles.btnExpand}
            onClick={() =>
              handleLoadNextThreadComments(comment?.id, comment_last_id)
            }
          >
            <AddSquare size="20" color="#91919d" />
          </div>
          <div
            className={styles.threadMoreComments}
            onClick={() =>
              handleLoadNextThreadComments(comment?.id, comment_last_id)
            }
          >
            Ще {comments_left} комментарів
          </div>
        </Flex>
      )}
      {error && <div>{error}</div>}
    </Flex>
  );
};

const memoizedThreadItem = memo(ThreadItem);

export { memoizedThreadItem as ThreadItem };
