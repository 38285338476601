import React from "react";

import UploadedImage from "../uploaded-image";
import styles from "./attachments-list.module.scss";

const AttachmentsList = ({ attachments, onRemoveAttachment }) => (
  <div className={styles.wrapper}>
    <div className={styles.attachments}>
      {attachments.map((attachment) => (
        <UploadedImage
          key={attachment.id}
          image={attachment}
          onRemove={() => onRemoveAttachment(attachment.id)}
        />
      ))}
    </div>
  </div>
);
export { AttachmentsList };
