import React from "react";

import {
  useGetSelfProfileState,
  useUpdateProfileMutation,
} from "../../../features/api/user-api";
import { ChangeProfileForm } from "../../forms/change-profile-form";

const ProfileSettings = () => {
  const { data: profile } = useGetSelfProfileState();
  const [updateProfile] = useUpdateProfileMutation();
  return (
    <div>
      <ChangeProfileForm onSubmit={updateProfile} data={profile || {}} />
    </div>
  );
};

export { ProfileSettings };
