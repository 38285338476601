import { Checkbox, Divider, Flex, Input, Modal, Radio } from "antd";
import { useState } from "react";

import { useReportPostByIdMutation } from "../../../../features/api/report-api";
import styles from "./report-modal.module.scss";

const ReportModal = ({ title, onCancel, postId }) => {
  const [formState, setFormState] = useState({
    value: "spam",
    reason: "",
    block: false,
  });

  const [reportPostById] = useReportPostByIdMutation();

  const handleChange = (field, value) => {
    setFormState((prevState) => ({ ...prevState, [field]: value }));
  };

  const onSubmitHandler = async () => {
    await reportPostById({
      postId,
      data: {
        reason_type: formState.value,
        reason: formState.reason,
        block: formState.block,
      },
    });
    onCancel();
  };

  return (
    <Modal
      centered
      width={700}
      title={title}
      open={true}
      okText={"Поскаржитись"}
      cancelText={"Скасувати"}
      onOk={onSubmitHandler}
      onCancel={onCancel}
      rootClassName={styles.reportModalWrap}
      transitionName=""
      closable={false}
    >
      <div className={styles.reportModalTitle}>
        Будь ласка, виберіть причину, чому ви хочете поскаржитися на цей пост:
      </div>
      <Flex vertical gap={8} className={styles.reportModal}>
        <Radio.Group
          name="post-report"
          direction="vertical"
          defaultValue={"1"}
          onChange={(e) => handleChange("value", e.target.value)}
          value={formState.value}
        >
          <Flex vertical gap={16} style={{ width: "100%" }}>
            <Radio value={"spam"} rootClassName={styles.reportModalItem}>
              <Flex vertical gap={2} className={styles.reportModalItemLabel}>
                <div className={styles.reportModalItemTitle}>
                  Порушує правила соцмережі
                </div>
                <div className={styles.reportModalItemText}>
                  Публікація містить спам або рекламні матеріали.
                </div>
              </Flex>
            </Radio>
            <Radio value={"hate"} rootClassName={styles.reportModalItem}>
              <Flex vertical gap={2} className={styles.reportModalItemLabel}>
                <div className={styles.reportModalItemTitle}>
                  Порушення авторських прав
                </div>
                <div className={styles.reportModalItemText}>
                  Публікація містить матеріали, що порушують авторські права.
                </div>
              </Flex>
            </Radio>
            <Radio value={"other"} rootClassName={styles.reportModalItem}>
              <Flex vertical gap={2} className={styles.reportModalItemLabel}>
                <div className={styles.reportModalItemTitle}>Інше</div>
                {formState.value === "other" && (
                  <div>
                    <Input.TextArea
                      autoFocus={true}
                      autoSize={{
                        minRows: 2,
                        maxRows: 8,
                      }}
                      onChange={(e) => handleChange("reason", e.target.value)}
                      placeholder="Будь ласка, вкажіть причину скарги"
                      className={styles.reportModalItemTextarea}
                    />
                  </div>
                )}
              </Flex>
            </Radio>
          </Flex>
        </Radio.Group>
        <Divider className={styles.reportModalDivider} />
        <Checkbox
          checked={formState.block}
          onChange={(e) => handleChange("block", e.target.checked)}
          className={styles.reportModalItemTitle}
        >
          Також заблокувати користувача{" "}
        </Checkbox>
      </Flex>
    </Modal>
  );
};

export { ReportModal };
