import { useGetSelfProfileState } from "@/features/api/user-api";
import * as $vars from "@/utils/constants/variables";
import { Badge } from "antd";
import { Notification } from "iconsax-react";
import React from "react";

import styles from "./header-notification-button.module.scss";

const HeaderNotificationButton = ({ onClick }) => {
  const { data: profile } = useGetSelfProfileState();

  const hasNewNotifications = profile?.flags?.is_new_events;

  return (
    <Badge
      status="success"
      onClick={onClick}
      dot={hasNewNotifications}
      color={$vars.colors.notificationsBadge}
      className={styles.button}
      offset={[-12, 15]}
      size="large"
    >
      <Notification size="24" color={$vars.colors.grey} />
    </Badge>
  );
};

export { HeaderNotificationButton };
