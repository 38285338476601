import React from "react";

import { Avatar } from "../avatar";
import s from "./avatar-with-badge.module.scss";

const AvatarWithBadge = ({ badge, profile_avatar }) => {
  return (
    <div className={s.wrapper}>
      <Avatar className={s.avatar} style={{}} profile_avatar={profile_avatar} />
      {badge && <div className={s.badge}>{badge}</div>}
    </div>
  );
};

export { AvatarWithBadge };
