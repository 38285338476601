export const NOTIFICATION_TYPES = {
  post_vote: "post_vote",
  comment_vote: "comment_vote",
  follow: "follow",
  post: "post",
  post_comment: "post_comment",
  thread_comment: "thread_comment",
  post_mention: "post_mention",
  comment_mention: "comment_mention",
  ask_for_unblock: "ask_for_unblock",
  reply_to_comment: "reply_to_comment",
};
