import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchCommentVote,
  fetchComments,
  fetchNextThreads,
  fetchPostComment,
  fetchRemoveComment,
  fetchThreadsForPost,
  refetchOneComment,
} from "../../../features/post-comments/comment-actions";
import {
  resetComments,
  selectComments,
  selectCommentsError,
  selectCommentsLastId,
  selectCommentsLeft,
  selectIsCommentsLoading,
  selectIsCommentsLoadingNext,
} from "../../../features/post-comments/comment-slice";
import { useCable } from "../../../hooks/useCable";

export const usePostComments = ({ id }) => {
  const cable = useCable();
  const dispatch = useDispatch();
  const data = useSelector(selectComments);
  const isLoading = useSelector(selectIsCommentsLoading);
  const isLoadingNext = useSelector(selectIsCommentsLoadingNext);
  const error = useSelector(selectCommentsError);
  const comments_left = useSelector(selectCommentsLeft);
  const comment_last_id = useSelector(selectCommentsLastId);

  const handleLoadNextComments = () => {
    dispatch(fetchNextThreads({ id, from_id: comment_last_id }));
  };

  const handleRemove = (commentId, parentId) => {
    dispatch(
      fetchRemoveComment({
        comment_id: commentId,
        thread_id: parentId,
        post_id: id,
      }),
    );
  };

  const handleReply = (commentId, { data }) => {
    if (data) {
      return handleAddComment({
        postId: id,
        body: data,
        commentId: commentId || null,
      });
    }
  };

  const handleAddComment = async ({ postId, body, commentId }) => {
    const res = await dispatch(
      fetchPostComment({
        postId,
        body,
        comment_id: commentId,
      }),
    );
    return res;
  };

  const handleLoadNextThreadComments = (commentId, comment_last_id) => {
    dispatch(
      fetchComments({
        from_id: comment_last_id,
        post_id: id,
        parent_id: commentId,
      }),
    );
  };

  const handleCommentRemove = async ({ comment_id, thread_id, post_id }) => {
    const res = await dispatch(
      fetchRemoveComment({ comment_id, thread_id, post_id }),
    );
    return res;
  };

  const handleVote = (commentId, voteType, parentId) => {
    dispatch(
      fetchCommentVote({
        comment_id: commentId,
        vote_type: voteType,
        post_id: id,
        parentId,
      }),
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchThreadsForPost({ id }));

      cable?.subscriptions?.create(
        {
          channel: "CommentChannel",
          post_id: id,
        },
        {
          received: (data) => {
            switch (data.event) {
              case "published":
                dispatch(
                  refetchOneComment({
                    comment_id: data.comment_id,
                    post_id: id,
                    thread_id: data.thread_id,
                  }),
                );
                break;
              default:
                break;
            }
          },
        },
      );
    }
    return () => {
      dispatch(resetComments());
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      cable?.subscriptions?.create(
        {
          channel: "CommentChannel",
          post_id: id,
        },
        {
          received: (data) => {
            switch (data.event) {
              case "published":
                dispatch(
                  refetchOneComment({
                    comment_id: data.comment_id,
                    post_id: id,
                    thread_id: data.thread_id,
                  }),
                );
                break;
              default:
                break;
            }
          },
        },
      );
    }
  }, [cable]);

  return {
    threadLastId: comment_last_id,
    data,
    isLoading: isLoading,
    isLoadingNext,
    error,
    commentsLeft: comments_left,
    handleLoadNextComments,
    handleLoadNextThreadComments,
    handleCommentRemove,
    handleVote,
    handleReply,
    handleRemove,
  };
};
