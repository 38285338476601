import { Select } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import ROUTES from "../../../../../../routing/routes";
import { SimpleMenu } from "../../../../../ui/simple-menu";
import { SETTINGS_MENU_ITEMS } from "../../constants";
import styles from "./settings-menu.module.scss";

const SettingsMenu = () => {
  const navigate = useNavigate();
  const type = location.pathname.split("/")[2];
  return (
    <div className={styles.wrapper}>
      <Select
        size={"large"}
        className={styles.select}
        value={type}
        onSelect={(value) => {
          if (value) {
            navigate(`${ROUTES.settings}/${value}`);
          }
        }}
        options={SETTINGS_MENU_ITEMS.map((item) => {
          return {
            value: item.key,
            label: item.title,
            disabled: item.disabled,
          };
        })}
      />
      <div className={styles.menu}>
        <SimpleMenu
          items={SETTINGS_MENU_ITEMS}
          activeItemKey={type}
          itemRender={(item) => {
            return (
              <Link
                className={styles.navItemLink}
                to={`${ROUTES.settings}/${item.key}`}
              >
                {item.title}
              </Link>
            );
          }}
        />
      </div>
    </div>
  );
};

export { SettingsMenu };
