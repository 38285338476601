import { useUpdateSinglePostMutation } from "@/features/api/posts-api";
import { fetchUpdatePost } from "@/features/posts/posts-actions";
import ROUTES from "@/routing/routes";
import { getPostForEdit } from "@/services/posts-service";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";

import * as $vars from "../../../utils/constants/variables";
import PostForm from "../../forms/post-form/post-form";
import { Spinner } from "../../ui/spinner";
import styles from "../new-post-modal/new-post-modal.module.scss";
import { BottomModal } from "../../ui/modals/bottom-modal";

const EditPostModal = ({ postId, onCancel }) => {
  const dispatch = useDispatch();
  const [updateSingle] = useUpdateSinglePostMutation();
  const [postData, setPostData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const isPostPage = useMatch(ROUTES.post);

  const getPost = async () => {
    setIsLoading(true);
    const post = await getPostForEdit(postId);
    setIsLoading(false);
    setPostData(post.data);
  };

  const onSave = async ({ data }) => {
    try {
      if (isPostPage) {
        await updateSingle({ postId, data: data }).then((res) => {
          onCancel();
          return res;
        });
      } else {
        await dispatch(fetchUpdatePost({ id: postId, body: data })).then(
          (res) => {
            onCancel();
            return res;
          },
        );
      }
    } catch (e) {
      console.log("onSave error", e);
    }
  };

  useEffect(() => {
    getPost();
  }, []);
  return (
    <BottomModal
      open={true}
      width={$vars.sizing.newPostModal}
      title={false}
      header={null}
      footer={null}
      centered={true}
      onCancel={onCancel}
      closable={false}
      closeIcon={false}
      maskClosable={false}
      rootClassName={styles.newPostModal}
    >
      {isLoading && <Spinner />}
      {!isLoading && (
        <PostForm post={postData} onCancel={onCancel} onSubmit={onSave} />
      )}
    </BottomModal>
  );
};

export { EditPostModal };
