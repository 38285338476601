import React, { useState } from "react";

import {
  IMAGE_GRID_COUNT,
  IMAGE_GRID_MORE_COUNT,
} from "../../../utils/constants/integers";
import cn from "../../../utils/helpers/className";
import { ImageList } from "./components/image-list";
import { ImageMore } from "./components/image-more";
import { SingleImage } from "./components/single-image";
import styles from "./image-grid.module.scss";

const ImageGrid = ({
  images,
  onImageClick,
  isComment = false,
  defaultExpanded = false,
  expandCallback,
  imageZoomCallback,
  showZoomedImage,
}) => {
  const [showAllImg, setShowAllImg] = useState(defaultExpanded);

  const imagesList = showAllImg
    ? images
    : images?.length > 0
      ? images.slice(0, IMAGE_GRID_COUNT)
      : [];

  //Check if single image
  const singleImage = images.length === 1;

  //Show all images cut
  const showAllImagesCut = images?.length > IMAGE_GRID_MORE_COUNT;

  return (
    <div className={cn(styles.wrapper, isComment ? styles.wrapperComment : "")}>
      <div
        className={cn(
          styles.gridContainer,
          isComment && styles.gridContainerComment,
          showAllImg && !singleImage ? styles.showAll : "",
        )}
      >
        {singleImage && (
          <SingleImage
            index={0}
            image={imagesList[0]}
            onClick={onImageClick}
            defaultExpanded={showZoomedImage}
            canHaveCut={isComment}
            classes={{
              notExpandedImageClassName: isComment
                ? styles.notExpandedSingeImage
                : "",
            }}
            imageZoomCallback={imageZoomCallback}
          />
        )}
        {imagesList?.length > 1 &&
          imagesList.map((image, index) => {
            return (
              <ImageList
                key={image.id}
                index={index}
                image={image}
                onImageClick={onImageClick}
              />
            );
          })}
      </div>
      {showAllImagesCut && (
        <div className={styles.gridFooter}>
          <span
            onClick={() => {
              setShowAllImg(!showAllImg);
              expandCallback?.(!showAllImg);
            }}
          >
            <ImageMore showAllImg={showAllImg} moreCount={images.length} />
          </span>
        </div>
      )}
    </div>
  );
};

export { ImageGrid };
