import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import {
  fetchNextPosts,
  fetchPosts,
  fetchRemovePost,
  fetchUpdateVote,
} from "../features/posts/posts-actions";
import {
  isFetchingNextOtherProfilePosts,
  resetOtherProfilePosts,
  selectHasMoreOtherProfilePosts,
  selectOtherProfileError,
  selectOtherProfileIsLoading,
  selectOtherProfilePostLastId,
  selectOtherProfilePosts,
  setIsRefetchNeeded,
} from "../features/posts/posts-slice";
import ROUTES from "../routing/routes";

const useOtherProfilePosts = ({ username, skip }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const posts = useSelector(selectOtherProfilePosts);
  const post_last_id = useSelector(selectOtherProfilePostLastId);
  const hasMorePosts = useSelector(selectHasMoreOtherProfilePosts);
  const isFetchingNextPage = useSelector(isFetchingNextOtherProfilePosts);
  const error = useSelector(selectOtherProfileError);
  const isLoading = useSelector(selectOtherProfileIsLoading);
  const isRefetchNeeded = useSelector(
    (state) => state.posts?.otherProfilePosts.isRefetchNeeded,
  );
  const [params] = useSearchParams();
  const q = params.get("q");

  useEffect(() => {
    if (username && isRefetchNeeded && !skip) {
      dispatch(
        fetchPosts({
          from_id: null,
          source: "otherProfilePosts",
          username,
          ...(q && { q }),
        }),
      );
      dispatch(
        setIsRefetchNeeded({ source: "otherProfilePosts", value: false }),
      );
    }
  }, [username && isRefetchNeeded,skip]);

  useEffect(() => {
    return () => {
      dispatch(resetOtherProfilePosts());
      dispatch(
        setIsRefetchNeeded({ source: "otherProfilePosts", value: true }),
      );
    };
  }, [username]);

  const getNextPosts = () => {
    if (post_last_id) {
      dispatch(
        fetchNextPosts({
          from_id: post_last_id,
          source: "otherProfilePosts",
          username,
          ...(q && { q }),
        }),
      );
    }
  };

  const onComment = ({ id, username }) => {
    navigate(generatePath(ROUTES.post, { id, username }));
  };

  const onPostAction = useCallback(
    ({ id, type, metadata }) => {
      switch (type) {
        case "comment": {
          onComment({ id: metadata.seq_id, username: metadata.username });
          break;
        }
        case "vote": {
          const { type: voteType } = metadata;
          dispatch(fetchUpdateVote({ postId: id, voteType }));
          break;
        }
        case "remove": {
          dispatch(fetchRemovePost({ postId: id }));
          break;
        }
        default:
          break;
      }
    },
    [dispatch, username],
  );

  return {
    posts,
    isLoading,
    error,
    onPostAction,
    getPosts: getNextPosts,
    hasMorePosts: hasMorePosts,
    isFetchingNextPage,
  };
};

export default useOtherProfilePosts;
