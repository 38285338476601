import { useModalHistory } from "../../hooks/useModalHistory";
import { HeaderNotificationButton } from "../ui/notifications/header-notification-button";
import { NotificationsDrawer } from "./notifications-drawer";

const Notifications = () => {
  const { open, changeState: setOpen } = useModalHistory("notifications");
  return (
    <>
      <HeaderNotificationButton onClick={() => setOpen(true)} />
      <NotificationsDrawer open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export { Notifications };
