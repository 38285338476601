import ROUTES from "@/routing/routes";
import { MIN_POST_LENGHT_HIDE } from "@/utils/constants/integers";
import cn from "@/utils/helpers/className";
import { formatDateTemplate } from "@/utils/helpers/date";
import { Flex, Typography } from "antd";
import { format } from "date-fns";
import React, { memo, useState } from "react";
import { Link, generatePath } from "react-router-dom";

import useHistoryState from "../../../hooks/useHistoryState";
import { WriteComment } from "../../comments/write-comment";
import { FormattedMetadata } from "../../formatted-metadata";
import { Avatar } from "../../ui/avatar";
import { LongReadControls } from "../../ui/long-read-controls";
import { PostReadMoreButton } from "../../ui/post-read-more-button";
import { ProfileInfoTooltip } from "../../user-popover";
import PostActions from "../post-actions";
import PostImages from "../post-images/post-images";
import styles from "./post-card.module.scss";

const PostCard = ({
  post: {
    id,
    body,
    votes_up,
    votes_down,
    reposts_count,
    comments_count,
    profile,
    published_at_str,
    published_at,
    external_images,
    vote,
    permissions,
    views_count,
    metadata,
    seq_id,
    share_url,
  },
  onAction,
  hasCommentForm,
  hasShadow,
  showAllText,
  showAllImages,
  showZoomedImage,
}) => {
  const { display_name, profile_image, username } = profile;
  const [fullTextOpenLocal, setFullTextOpenLocal] = useState(false);
  const [, setHistoryState] = useHistoryState(id);

  const handleToggleFullText = () => {
    setHistoryState(!fullTextOpenLocal, "fullTextOpen");
    setFullTextOpenLocal(!fullTextOpenLocal);
  };

  const canViewAllText = showAllText || fullTextOpenLocal;

  const onActionWithId = (type, metadata) => {
    return onAction({
      id,
      type,
      metadata: {
        ...metadata,
        username,
        seq_id,
      },
    });
  };

  const profile_avatar = profile_image?.small?.url;

  return (
    <div
      className={cn(
        styles.postWrap,
        published_at === null ? styles.notPublished : "",
        hasShadow ? styles.hasShadow : "",
      )}
    >
      <section id={`post-${id}-start`} className={styles.longreadAnchor} />
      <Flex vertical>
        <Flex className={styles.postHeader} gap={8} align={"top"}>
          <Link to={generatePath(ROUTES.otherProfile, { slag: username })}>
            <ProfileInfoTooltip username={username}>
              <div>
                <Avatar
                  profile_avatar={profile_avatar}
                  className={styles.avatar}
                />
              </div>
            </ProfileInfoTooltip>
          </Link>
          <div>
            <Link to={generatePath(ROUTES.otherProfile, { slag: username })}>
              <ProfileInfoTooltip username={username}>
                <div className={styles.postAuthor}>{display_name}</div>
              </ProfileInfoTooltip>
            </Link>

            <Link
              className={styles.postDateTime}
              to={generatePath(ROUTES.post, { username, id: seq_id })}
              title={
                published_at?.length && format(published_at, formatDateTemplate)
              }
            >
              {published_at_str}
            </Link>
          </div>
        </Flex>
        {body && (
          <div className={styles.postBodyWrap}>
            <Typography.Paragraph className={cn(styles.postBody, "post-body")}>
              <FormattedMetadata
                text={
                  canViewAllText ? body : body.slice(0, MIN_POST_LENGHT_HIDE)
                }
                metadata={metadata}
              />
              {body.length > MIN_POST_LENGHT_HIDE && (
                <span onClick={() => handleToggleFullText((prev) => !prev)}>
                  {!canViewAllText && (
                    <PostReadMoreButton
                      style={{
                        userSelect: "none",
                      }}
                    />
                  )}
                </span>
              )}
            </Typography.Paragraph>
            {body.length > MIN_POST_LENGHT_HIDE && !showAllText && (
              <LongReadControls id={id} />
            )}
          </div>
        )}
        <section id={`post-${id}-end`} className={styles.longreadAnchor} />
        {external_images?.length > 0 && (
          <PostImages
            metadata={metadata}
            images={external_images}
            defaultExpanded={showAllImages}
            showZoomedImage={showZoomedImage}
            post={{
              body,
              id,
              profile: { display_name, profile_image, username },
              published_at_str,
              published_at,
            }}
          />
        )}
        <PostActions
          postLink={generatePath(ROUTES.post, { id: seq_id, username })}
          shareUrl={share_url}
          permissions={permissions}
          activeVote={vote?.vote_type}
          votesCount={vote?.vote_sum}
          onAction={onActionWithId}
          counters={{
            id,
            votes_up,
            votes_down,
            reposts_count,
            comments_count,
            views_count,
          }}
        />
        {hasCommentForm && (
          <div className={styles.postComments}>
            <WriteComment
              hasInput
              noMargin
              onSubmit={({ data }) => onActionWithId("reply", data)}
              autoFocus={false}
            />
          </div>
        )}
      </Flex>
    </div>
  );
};

const PostCardMemo = memo(PostCard);
export { PostCardMemo as PostCard };
