import { useState } from "react";

import { restorePassword } from "../../../../services/oauth-service";

const useRestorePassword = (onSuccess) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const HandleRestorePassword = async (loginData) => {
    setIsLoading(true);
    setError(null);
    setData(null);

    try {
      const response = await restorePassword(loginData);
      setData(response);
      onSuccess?.();
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    trigger: HandleRestorePassword,
    isLoading,
    error,
    data,
  };
};

export default useRestorePassword;
