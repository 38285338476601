import { Dropdown, Flex } from "antd";
import React, { useMemo } from "react";
import { BsThreeDots } from "react-icons/bs";

import { RemoveModal } from "../../ui/modals/remove-modal";
import { Votes } from "../../ui/votes";
import {
  COMMENTS_ACTION_TYPES,
  getCommentsMoreMenuItems,
  toolbarActions,
} from "../constants";
import styles from "./comments-actions.module.scss";

const CommentsActions = ({
  votesCount,
  activeVote,
  permissions,
  onVote,
  onMoreDotsSelect,
  onToolbarActionClick,
}) => {
  const [removeModalVisible, setRemoveModalVisible] = React.useState(false);
  const dropdownItems = useMemo(
    () => getCommentsMoreMenuItems(permissions),
    [permissions],
  );

  const toolbarHandlers = {
    [COMMENTS_ACTION_TYPES.EDIT]: () =>
      onMoreDotsSelect(COMMENTS_ACTION_TYPES.EDIT),
    [COMMENTS_ACTION_TYPES.REPORT]: () =>
      onMoreDotsSelect(COMMENTS_ACTION_TYPES.REPORT),
    [COMMENTS_ACTION_TYPES.REMOVE]: () => setRemoveModalVisible(true),
  };

  const handleMoreDotsClick = (type) => {
    if (toolbarHandlers[type]) {
      toolbarHandlers[type]();
    }
  };

  return (
    <>
      {removeModalVisible && (
        <RemoveModal
          title={"Ви впевнені, що хочете видалити цей коментар?"}
          onSubmit={() => onMoreDotsSelect(COMMENTS_ACTION_TYPES.REMOVE)}
          onCancel={() => setRemoveModalVisible(false)}
        />
      )}
      <Flex align={"center"} gap={8}>
        <Votes
          onVote={onVote}
          activeVote={activeVote}
          votesCount={votesCount}
        />
        <Flex gap={8} align="center">
          {toolbarActions.map((action) => (
            <Flex
              className={styles.actionButton}
              key={action.type}
              onClick={() => onToolbarActionClick(action.type)}
            >
              <div className={styles.actionIconText}>{action.text}</div>
            </Flex>
          ))}
        </Flex>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: dropdownItems,
            onClick: (e) => handleMoreDotsClick(e.key),
          }}
          placement="top"
          arrow
        >
          <Flex className={styles.actionButton}>
            <div className={styles.actionIcon}>
              <BsThreeDots size={18} />
            </div>
          </Flex>
        </Dropdown>
      </Flex>
    </>
  );
};

export { CommentsActions };
