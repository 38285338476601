import {
  getNotificationBody,
  getNotificationIcon,
  getNotificationUrl,
} from "@/components/notifications/lib/helpers";
import React from "react";
import { Link, generatePath } from "react-router-dom";

import ROUTES from "../../../../routing/routes";
import { AvatarWithBadge } from "../../avatar-with-badge";
import { NotificationCard } from "../../cards/notification-card/notification-card";

const UserNotification = ({
  notification,
  handleCloseDrawer,
  hasSameColorAtAfter,
}) => {
  const profileLink = generatePath(ROUTES.otherProfile, {
    slag: notification.profile.username,
  });

  return (
    <NotificationCard
      clickHandler={() => handleCloseDrawer(false)}
      link={getNotificationUrl(
        notification.event_type,
        notification.data,
        notification.profile,
      )}
      color={notification?.color}
      hasSameColorAtAfter={hasSameColorAtAfter}
      key={notification.id}
      icon={
        <Link
          to={profileLink}
          onClick={(e) => {
            handleCloseDrawer(false);
            e.stopPropagation();
          }}
        >
          <AvatarWithBadge
            profile_avatar={notification?.profile?.profile_image?.small.url}
            badge={getNotificationIcon(
              notification.event_type,
              notification.data,
            )}
          />
        </Link>
      }
      title={notification.profile.display_name}
      subTitle={getNotificationBody(notification.event_type)}
      timeString={notification.published_at_str}
    />
  );
};

export { UserNotification };
