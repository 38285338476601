import { useLazyGetNotificationsQuery } from "@/features/api/notifications-api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { api } from "../features/api/api";

const useNotifications = ({ open }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [fromId, setFromId] = useState(null);
  const [trigger, { isFetching, isError }] =
    useLazyGetNotificationsQuery(undefined);

  const getNotifications = async (fromId) => {
    if (!fromId && !notifications?.length) {
      await trigger(undefined).then((response) => {
        if (response.data) {
          setNotifications(response.data.events);
          setFromId(response.data.event_last_id);
          dispatch(
            api.util.updateQueryData(
              "getSelfProfile",
              undefined,
              (draftProfile) => {
                draftProfile.flags.is_new_events = false;
              },
            ),
          );
        }
      });
    }
  };

  useEffect(() => {
    if (open) {
      getNotifications();
    }
  }, [fromId]);

  const fetchNext = async () => {
    if (!fromId) return;
    await trigger(fromId).then((response) => {
      if (response.data) {
        setNotifications([...notifications, ...response.data.events]);
        setFromId(response.data.event_last_id);
      }
    });
  };

  return {
    data: notifications,
    isLoading: isFetching,
    isError,
    refetch: trigger,
    hasMore: !!fromId,
    fetchNext,
  };
};

export default useNotifications;
