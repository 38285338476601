import { createSlice } from "@reduxjs/toolkit";

import { rootReducer } from "../rootReducer";

const initialState = {
  authModalOpen: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    openAuthModal: (state) => {
      state.authModalOpen = true;
    },
    closeAuthModal: (state) => {
      state.authModalOpen = false;
    },
  },
}).injectInto(rootReducer);

export const { openAuthModal, closeAuthModal } = userSlice.actions;

export const selectIsLoggedIn = (state) => state?.user?.profile?.username;

export const { actions, reducer } = userSlice;
