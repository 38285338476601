import React from "react";

import { UserNotification } from "../user-notification";
import styles from "./notification-list.module.scss";

const NotificationsList = ({ notifications, handleCloseDrawer }) => {
  return (
    notifications?.length > 0 &&
    notifications?.map((notification, index) =>
      notification?.class === "Placeholder" ? (
        <div key={notification.text} className={styles.divider}>
          {notification?.text}
        </div>
      ) : (
        <UserNotification
          key={notification.id || notification.text}
          notification={notification}
          handleCloseDrawer={handleCloseDrawer}
          hasSameColorAtAfter={
            notifications[index + 1]?.color === notification.color
          }
        />
      ),
    )
  );
};

export { NotificationsList };
