export const sizing = {
  newPostModal: "920px",
  aside: "184px",
};

export const colors = {
  light: "#F8F8F8",
  lightPurple: "#ECEBF2",
  dark: "#202020",
  grey: "#717183",
  primary: "#7e7aff",
  red: "#F2525B",
  bodyBg: "#FCFCFC",
  postDatetime: "#717183",
  commentAuthor: "#202020",
  commentDatetime: "#717183",
  primaryColor: "#7e7aff",
  commentsLine: "#cccccc",
  footerSection: "#F6F8FC",
  footerButton: "#ECF0F9",
  footerIcon: "#717183",
  footerIconHoverBg: "#ECEBF2",
  notificationsBadge: "#F57B0B",
};

export const typography = {
  postBody: "16px",
  postAuthor: "16px",
  postDatetime: "16px",
  commentHeader: "16px",
  commentBody: "16px",
  commentLineHeight: "1.43",
};

export const padding = {
  cardPadding: "16px",
};

export const borderRadius = {
  footerButton: "18px",
  lg: "16px",
  profileAvatar: "10px",
  md: "8px",
};

export const boxShadow = {
  lg: "0 12px 30px 0 rgba(0, 0, 0, 0.09)",
  header: "0 0 15px rgb(145 145 145 / 32%)",
};

export const resolutions = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};
