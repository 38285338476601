import { useSignUpMutation } from "../../../../features/api/user-api";

const useSignUp = ({ onSuccess }) => {
  const [signUp, { isLoading: isSignUpLoading, error: signUpError }] =
    useSignUpMutation();

  const handleSignUp = (data) => {
    return signUp(data)
      .unwrap()
      .then(() => {
        onSuccess?.();
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  return {
    handleSignUp,
    signUpError,
    isSignUpLoading,
  };
};

export default useSignUp;
