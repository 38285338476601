import { useNavigate } from "react-router-dom";

import styles from "./notification-card.module.scss";

const NotificationCard = ({
  title,
  subTitle,
  icon,
  link,
  clickHandler,
  timeString,
  color,
  hasSameColorAtAfter,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    clickHandler();
    navigate(link);
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <div>
        <div
          className={styles.coloredLine}
          style={{
            height: hasSameColorAtAfter ? "calc(100% + 10px)" : "100%",
            backgroundColor: color || "transparent",
            borderColor: color || "transparent",
          }}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.body}>
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div className={styles.time}>{timeString}</div>
          </div>
          <div className={styles.description}>{subTitle}</div>
        </div>
      </div>
    </div>
  );
};

export { NotificationCard };
