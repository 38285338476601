import React from "react";
import { ArrowDown2 } from "iconsax-react";
import * as $vars from "@/utils/constants/variables";
import styles from "./post-read-more-button.module.scss";

export const PostReadMoreButton = () => {
  return (
    <div className={styles.readMore}>
      <span className={styles.readMoreSpan}>
        Читати далі
        <ArrowDown2 size="16" color={$vars.colors.primaryColor} />
      </span>
    </div>
  );
}; 