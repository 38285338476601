import { Divider } from "antd";
import React from "react";

import RemindForm from "../../forms/remind-password";
import useRestorePassword from "../start/hooks/use-restore-password";

const RestorePassword = ({ error, onSubmit, onSuccess }) => {
  const {
    trigger: handleRestorePassword,
    isLoading: isRestoreLoading,
    error: restoreError,
  } = useRestorePassword(onSuccess);

  return (
    <>
      <RemindForm onSubmit={handleRestorePassword} error={restoreError} />
      <Divider />
    </>
  );
};

export default RestorePassword;
