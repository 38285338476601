import cn from "../../../../../utils/helpers/className";
import styles from "../../image-grid.module.scss";
import { Skeleton } from "antd";
import React from "react";

const ImageList = ({ index, image, onImageClick }) => {
  return (
    <div
      className={cn(
        styles.item,
      )}
      key={index}
    >
      <div
        className={cn(
          styles.imageWrapper,
        )}

        onClick={() =>
          image?.large?.url &&
          image?.medium?.url &&
          onImageClick({ index, image })
        }
      >
        {image?.medium?.url && (
          <img
            className={cn(
              styles.image,
            )}
            loading="lazy"
            src={image?.medium?.url}
            alt={`Image ${index}`}
          />
        )}
        {!image?.medium?.url && <Skeleton.Image active></Skeleton.Image>}
      </div>
    </div>
  )
}

export { ImageList };