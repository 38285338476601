import { MessageText1 } from "iconsax-react";

export const COMMENTS_ACTION_TYPES = {
  COMMENT: "comment",
  REPOST: "repost",
  REMOVE: "remove",
  EDIT: "edit",
  REPORT: "report",
};

export const toolbarActions = [
  {
    type: COMMENTS_ACTION_TYPES.COMMENT,
    icon: <MessageText1 size="18" style={{ width: "16px", height: "16px" }} />,
    style: { color: "$grey" },
    text: "Відповісти",
  },
];

export const getCommentsMoreMenuItems = (permissions) =>
  [
    permissions.can_edit && {
      key: COMMENTS_ACTION_TYPES.EDIT,
      label: "Редагувати",
    },
    {
      key: COMMENTS_ACTION_TYPES.REPORT,
      label: "Поскаржитись",
    },
    permissions.can_delete && {
      key: COMMENTS_ACTION_TYPES.REMOVE,
      label: "Видалити",
      danger: true,
    },
  ].filter(Boolean);

export function sortArrayWithPostDataToEnd(arr) {
  const sortedArray = arr.slice();

  sortedArray.sort((a, b) => {
    if (!a.published_at) return 1;
    if (!b.published_at) return -1;
    return new Date(a.published_at) - new Date(b.published_at);
  });

  return sortedArray;
}
