import { Button, Space, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useRemoveProfileMutation } from "../../../../../features/api/user-api";
import { useModalHistory } from "../../../../../hooks/useModalHistory";
import ROUTES from "../../../../../routing/routes";
import { remove } from "../../../../../utils/helpers/local-storage";
import { RemoveModal } from "../../../../ui/modals/remove-modal";
import styles from "./deactivate-account.module.scss";

const DeactivateAccount = () => {
  const navigate = useNavigate();
  const { open, changeState: setConfirmModal } =
    useModalHistory("confirmRemoveModal");
  const [removeProfile, { error }] = useRemoveProfileMutation();

  const onRemoveConfirm = () => {
    try {
      removeProfile()
        .unwrap()
        .then(() => {
          remove("accessToken");
          remove("refreshToken");
          navigate(ROUTES.home);
        });
    } catch (error) {
      console.log("error", error);
    } finally {
      setConfirmModal(false);
    }
  };

  const onRemoveClick = () => {
    setConfirmModal(true);
  };

  const onCancel = () => {
    setConfirmModal(false);
  };

  return (
    <Space className={styles.wrapper} direction={"vertical"}>
      <Typography>
        Акаунт буде позначено для видалення та видалено протягом 30 днів. Процес
        буде скасовано, якщо ви увійдете знову. Після повного видалення ви
        більше не зможете увійти.
      </Typography>
      <Button danger onClick={onRemoveClick} size={"large"} className={styles.button}>
        Видалити акаунт
      </Button>
      {open && (
        <RemoveModal
          title={"Підтвердження"}
          text={"Ви дійсно бажаєте видалити аккаунт?"}
          onCancel={onCancel}
          onSubmit={onRemoveConfirm}
        />
      )}
      {error && (
        <Typography.Text type={"danger"}>
          {"Щось пішло не так, спробуємо ще раз"}
        </Typography.Text>
      )}
    </Space>
  );
};

export { DeactivateAccount };
