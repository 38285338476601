import { api } from "./api";

export const postsApi = api.injectEndpoints({
  tagTypes: ["post"],
  endpoints: (builder) => ({
    getPostById: builder.query({
      query: ({ id, username }) => ({
        url: `/profiles/${username}/posts/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, postId) => [{ type: "post", id: postId }],
    }),
    updateVoteOnSinglePost: builder.mutation({
      query: ({ postId, voteType, seq_id }) => ({
        url: `/posts/${postId}/votes`,
        method: "POST",
        body: { vote_type: voteType },
      }),
      async onQueryStarted({ seq_id, username }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedVote } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              "getPostById",
              { id: seq_id, username },
              (draft) => {
                draft.vote = updatedVote;
              },
            ),
          );
        } catch (e) {
          console.log(e);
        }
      },
    }),
    updateSinglePost: builder.mutation({
      query: ({ postId, data }) => ({
        url: `/posts/${postId}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "post", id }],
    }),
    removePost: builder.mutation({
      query: (id) => ({
        url: `/posts/${id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPostByIdQuery,
  useUpdateVoteOnSinglePostMutation,
  useRemovePostMutation,
  useUpdateSinglePostMutation,
} = postsApi;
