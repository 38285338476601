import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import React, { useEffect, useRef, useState } from "react";

import styles from "./long-read-controls.module.scss";

export const LongReadControls = ({ id }) => {
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const startElement = document.getElementById(`post-${id}-start`);
    const postEndElement = document.getElementById(`post-${id}-end`);
    const postHeight = postEndElement.offsetTop - startElement.offsetTop;
    const windowHeight = window.innerHeight;
    if (postHeight > windowHeight) {
      setIsVisible(true);
    }
    if (!startElement || !postEndElement || !ref.current) return;

    const handleScroll = () => {
      const startRect = startElement.getBoundingClientRect();
      const endRect = postEndElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (startRect.top >= 0) {
        setProgress(0);
        return;
      }
      if (endRect.bottom <= windowHeight) {
        setProgress(100);
        setIsVisible(false);
        return;
      }
      const totalHeight = endRect.top - startRect.top;
      const scrolledHeight = Math.abs(startRect.top);
      const visibleContentHeight = totalHeight - windowHeight;

      if (visibleContentHeight <= 0) {
        setProgress(100);
        setIsVisible(false);
      } else {
        const scrollPercentage = Math.min(
          100,
          Math.max(0, (scrolledHeight / visibleContentHeight) * 100),
        );
        setProgress(scrollPercentage);
        setIsVisible(scrollPercentage > 0 && scrollPercentage < 100);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          window.addEventListener("scroll", handleScroll);
          handleScroll();
        } else {
          window.removeEventListener("scroll", handleScroll);
        }
      },
      { threshold: 0.1 },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, [id]);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      const rect = element.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      window.scrollTo({
        top: rect.top + scrollTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <div
      ref={ref}
      className={styles.longreadBar}
      style={{
        display: isVisible ? "flex" : "none",
      }}
    >
      {isVisible && (
        <>
          <button
            className={styles.navigationButton}
            onClick={() => scrollToElement(`post-${id}-start`)}
          >
            <ArrowUp2 size="15" color="#7e7aff" />
            &nbsp; На початок
          </button>
          <button
            className={styles.navigationButton}
            onClick={() => scrollToElement(`post-${id}-end`)}
          >
            У кінець&nbsp;
            <ArrowDown2 size="15" color="#7e7aff" />
          </button>
          <div
            className={styles.longreadBarProgress}
            style={{ width: `${progress}%` }}
          />
        </>
      )}
    </div>
  );
};
