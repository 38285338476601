const ROUTES = {
  profile: "/profile",
  otherProfile: "/:slag",
  settings: "/settings",
  passwordReset: "/password-reset",
  emailConfirm: "/email-confirmation",
  profileSettings: "profile",
  accountSettings: "account",
  contentSettings: "content",
  blackListSettings: "black-list",
  home: "/",
  feed: "/posts",
  post: "/:username/posts/:id",
  error: "/error",
  notFoundPage: "/static/404",
  noPermission: "/static/403",
  privacyPolicy: "/static/privacy-policy",
  rules: "/static/rules",
};

export default ROUTES;
