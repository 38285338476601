import { Flex } from "antd";

import ThreadItem from "./thread-item";

const CommentsList = ({
  threads,
  onVote,
  onAction,
  onReply,
  onRemove,
  handleLoadNextThreadComments,
}) => {
  return (
    <Flex vertical gap={12}>
      {threads?.length > 0 &&
        threads.map((thread) => (
          <ThreadItem
            key={thread.id}
            comment={thread}
            onAction={onAction}
            onRemove={onRemove}
            onReply={onReply}
            onVote={onVote}
            handleLoadNextThreadComments={handleLoadNextThreadComments}
          />
        ))}
    </Flex>
  );
};

export { CommentsList };
