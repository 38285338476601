import { Button } from "antd";

import rightSidebarImage1 from "../../../assets/images/right-sidebar-1.png";
import styles from "./right-column.module.scss";

const RightColumn = () => {
  return (
    <div className={styles.column}>
      <div className={styles.content}>
        <div className={styles.widget}>
          <img src={rightSidebarImage1} className={styles.image} alt="Це рання версія сайту, багато функцій наразі вимкнено" />
          <a
            href="https://forms.gle/7E8TRPBC3dSx33SG9"
            rel="noreferrer"
            target={"_blank"}
            className={styles.link}
          >
            <Button className={styles.reportButton}>Повідомити про баг</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default RightColumn;
