import React from "react";

import cn from "../../../utils/helpers/className";
import styles from "./simple-menu.module.scss";

const SimpleMenu = ({
  items = [],
  wrapperClassName,
  itemClassName,
  activeItemKey,
  itemRender,
}) => {
  return (
    <div className={cn(styles.wrapper, wrapperClassName && wrapperClassName)}>
      {items.map((item) => (
        <div
          key={item.key}
          className={cn(
            styles.item,
            itemClassName && itemClassName,
            activeItemKey === item.key && styles.active,
            item.disabled && styles.disabled,
          )}
        >
          {itemRender ? itemRender(item) : item.title}
        </div>
      ))}
    </div>
  );
};

export { SimpleMenu };
