import { useState } from "react";
import { Skeleton } from "antd";

import { MIN_IMG_PROPORTION } from "../../../../../utils/constants/integers";
import cn from "../../../../../utils/helpers/className";
import { ImageCut } from "../image-cut";
import styles from "./single-image.module.scss";

const SingleImage = ({
  index,
  image,
  onClick,
  defaultExpanded,
  canHaveCut,
  classes,
  imageZoomCallback,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const imageFormat = image?.medium?.height / image?.medium?.width;
  const hasCut = imageFormat > MIN_IMG_PROPORTION;
  const showLongImageCut = hasCut && !isExpanded && !canHaveCut;

  //Show long image
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    imageZoomCallback?.(!isExpanded);
  };

  return (
    <div className={styles.singleWrap}>
      <div
        className={cn(
          styles.single,
          hasCut && !isExpanded && styles.singleHasCut,
        )}
        onClick={() =>
          image?.large?.url && image?.medium?.url && onClick({ index, image })
        }
      >
        {image?.medium?.url && (
          <img
            className={cn(
              styles.singleImage,
              hasCut && !isExpanded && styles.singleImageHasCut,
              classes.notExpandedImageClassName &&
                !isExpanded &&
                classes.notExpandedImageClassName,
            )}
            loading="lazy"
            height={image?.medium?.height}
            src={image?.medium?.url}
            alt={`Image ${index}`}
          />
        )}
        {!image?.medium?.url && <Skeleton.Image active style={{width: image?.medium?.width, height: image?.medium?.height }} className={styles.skeleton}></Skeleton.Image>}
      </div>
      {showLongImageCut && (
        <div
          className={cn(styles.gridFooter, hasCut && styles.gridFooterHasCut)}
        >
          <span onClick={handleExpand}>
            <ImageCut isExpanded={isExpanded} />
          </span>
        </div>
      )}
    </div>
  );
};

export { SingleImage };
