import React from "react";

import cn from "../../../utils/helpers/className";
import styles from "./settings-layout.module.scss";

const SettingsLayout = ({
  title,
  sidebar,
  children,
  classes = {
    wrapper: "",
    card: "",
    column: "",
    title: "",
    content: "",
  },
}) => {
  return (
    <div className={cn(styles.wrapper, classes.wrapper)}>
      <div className={cn(styles.card, classes.card)}>
        <div className={cn(styles.column, classes.column)}>
          <h1 className={cn(styles.title, classes.title)}>{title}</h1>
          <div className={cn(styles.content, classes.content)}>
            {sidebar}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export { SettingsLayout };
