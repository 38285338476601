import { useLocation, useNavigate } from "react-router-dom";

const useHistoryState = (id) => {
  const navigate = useNavigate();
  const location = useLocation();

  const setHistoryState = (value, name) => {
    navigate(
      {
        pathname: location.pathname,
        search: location.search,
      },
      {
        replace: true,
        preventScrollReset: true,
        state: {
          ...location.state,
          [id]: {
            ...location.state?.[id],
            [name]: value,
          },
        },
      },
    );
  };

  return [location.state?.[id] || {}, setHistoryState];
};

export default useHistoryState;
