import React from "react";

import useHistoryState from "../../../hooks/useHistoryState";
import { useModalHistory } from "../../../hooks/useModalHistory";
import ImageGrid from "../../ui/image-grid";
import ModalImageUserInfo from "../../ui/modal-image-user-info";
import { ImageModal } from "../../ui/modals/image-modal";

const PostImages = ({
  images,
  post,
  metadata,
  isComment,
  defaultExpanded,
  showZoomedImage,
}) => {
  const [, setHistoryState] = useHistoryState(post.id);

  const { open, changeState: setOpen } = useModalHistory(
    `imageGrid-${post.id}`,
  );

  const onNavigation = (e) => {
    if (!e) {
      setOpen(null);
    } else {
      setOpen(e);
    }
  };

  const historyState = (value, name) => {
    setHistoryState(value, name);
  };

  return (
    <>
      {open && (
        <ImageModal
          infoSection={
            <ModalImageUserInfo
              metadata={metadata}
              body={post.body}
              username={post.profile.username}
              name={post.profile.display_name}
              avatar={post.profile.profile_image?.small?.url}
              date={post.published_at_str}
            />
          }
          images={images}
          startFromIndex={open?.index}
          onNavigation={onNavigation}
        />
      )}
      <ImageGrid
        imageZoomCallback={(value) => historyState(value, "zoomImageOpen")}
        expandCallback={(value) => historyState(value, "showAllImages")}
        isComment={isComment}
        defaultExpanded={defaultExpanded}
        images={images}
        onImageClick={({ index, image }) =>
          post?.published_at && setOpen({ index, image })
        }
        showZoomedImage={showZoomedImage}
      />
    </>
  );
};

export default PostImages;
