import React from "react";

import CableProvider from "./CableProvider";
import PostsUpdateProvider from "./PostsUpdateProvider";
import ProfileUpdateProvider from "./ProfileUpdateProvider";

const WebsocketProviders = ({ children }) => {
  return (
    <CableProvider>
      <PostsUpdateProvider>
        <ProfileUpdateProvider>{children}</ProfileUpdateProvider>
      </PostsUpdateProvider>
    </CableProvider>
  );
};

export default WebsocketProviders;
