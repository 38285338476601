import { Button, Flex, Input, Space, Typography, notification } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import styles from "../../../pages/settings/settings.module.scss";

const validationSchema = Yup.object().shape({
  // email: Yup.string().required("Email is required"),
  // password: Yup.string().required("Password is required"),
});

const { Text } = Typography;

const ChangeProfileForm = ({ onSubmit, data }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  return (
    <>
      {contextHolder}
      <Formik
        initialValues={{
          display_name: data?.display_name || "",
          username: data?.username || "",
          email: data?.email || "",
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          onSubmit({
            display_name: values?.display_name,
            username: values?.username,
            owner_attributes: {
              email: values?.email,
            },
          }).then((res) => {
            setSubmitting(false);
            if (res.error) {
              const errors = res.error.data?.details;
              for (let fieldErrors in errors) {
                setFieldError(fieldErrors, errors[fieldErrors].join(", "));
              }
            }
            if (res?.data) {
              notificationApi.success({
                message: "Профіль успішно оновлено",
              });
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <Flex vertical gap={16}>
              <Flex vertical gap={4}>
                <div className={styles.subtitle}>Ім&apos;я та Прізвище</div>
                <Input
                  size={"large"}
                  type="text"
                  name="display_name"
                  placeholder="Ім'я та Прізвище"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.display_name}
                />
                {errors.display_name && touched.display_name ? (
                  <Text type={"danger"}>{errors.display_name}</Text>
                ) : null}
              </Flex>

              <Flex vertical gap={4}>
                <div className={styles.subtitle}>Юзернейм</div>
                <Input
                  size={"large"}
                  type="text"
                  name="username"
                  placeholder="Юзернейм"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                />
                {errors.username && touched.username ? (
                  <Text type={"danger"}>{errors.username}</Text>
                ) : null}
              </Flex>

              <Flex vertical gap={4}>
                <div className={styles.subtitle}>Email</div>
                <Input
                  size={"large"}
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <Text type={"danger"}>{errors.email}</Text>
                ) : null}
              </Flex>
              {/* {error && <Text type={"danger"}>{error}</Text>} */}

              <Flex gap={16} justify="flex-end">
                <Button
                  htmlType="submit"
                  className={styles.formButton}
                  size={"large"}
                  type={"primary"}
                  disabled={isSubmitting}
                >
                  Зберегти
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};

export { ChangeProfileForm };
