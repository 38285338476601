import { setIsRefetchNeeded } from "@/features/posts/posts-slice";
import ROUTES from "@/routing/routes";
import * as $vars from "@/utils/constants/variables";
import Sider from "antd/es/layout/Sider";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { NewPostModal } from "../../post/new-post-modal/new-post-modal";
import AsideLinks from "../aside-links";
import AsideNav from "../aside-nav";
import styles from "./aside.module.scss";

const Aside = ({ open, closeSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //modified for not logged in user
  const [params] = useSearchParams();
  const type = params.get("type");

  const onSelectCategory = (category) => {
    if (isMobile) {
      closeSidebar();
    }
    navigate({
      pathname: ROUTES.feed,
      search: createSearchParams({ type: category }).toString(),
    });
    dispatch(
      setIsRefetchNeeded({
        source: "feedPosts",
        value: true,
      }),
    );
  };

  const onSuccessPost = () => {
    if (isMobile) {
      closeSidebar();
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Sider
      theme={"custom"}
      className={styles.siderCustom}
      width={$vars.sizing.aside}
      breakpoint="xl"
      collapsedWidth="0"
      collapsed={false}
      trigger={null}
    >
      <div className={styles.siderColumn}>
        <NewPostModal onSuccess={onSuccessPost} />
        <div className={styles.siderMenus}>
          <AsideNav onSelect={onSelectCategory} selectedCategory={type} />
          <AsideLinks />
        </div>
      </div>
    </Sider>
  );
};

export default Aside;
