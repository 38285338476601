import { ReactComponent as GalleryIcon } from "@/assets/icons/post/gallery.svg";
import styles from "./image-more.module.scss";
import { ArrowUp2 } from "iconsax-react";
import React from "react";
import * as $vars from "@/utils/constants/variables";

const ImageMore = ({ showAllImg, moreCount }) => {
  return (
    <div className={styles.imageMore}>
      {showAllImg ?
        <>
          Згорнути
          <ArrowUp2 size="16" color={$vars.colors.primaryColor} />
        </> :
        <>
          Показати більше
          <GalleryIcon />
          <div className={styles.imageMoreLabel}>{moreCount}</div>
        </>
      }

    </div>
  );
};

export { ImageMore };
