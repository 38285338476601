import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import {
  fetchNextPosts,
  fetchPosts,
  fetchRemovePost,
  fetchUpdateVote,
} from "../features/posts/posts-actions";
import {
  isFetchingNextProfilePosts,
  resetProfilePosts,
  selectHasMoreProfilePosts,
  selectProfileError,
  selectProfileIsLoading,
  selectProfilePostLastId,
  selectProfilePosts,
  setIsRefetchNeeded,
} from "../features/posts/posts-slice";
import ROUTES from "../routing/routes";

const useProfilePosts = ({ username }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const posts = useSelector(selectProfilePosts);
  const post_last_id = useSelector(selectProfilePostLastId);
  const hasMorePosts = useSelector(selectHasMoreProfilePosts);
  const isFetchingNextPage = useSelector(isFetchingNextProfilePosts);
  const error = useSelector(selectProfileError);
  const isLoading = useSelector(selectProfileIsLoading);
  const isRefetchNeeded = useSelector(
    (state) => state.posts?.profilePosts.isRefetchNeeded,
  );
  const [params] = useSearchParams();
  const q = params.get("q");

  useEffect(() => {
    if (username && isRefetchNeeded) {
      dispatch(
        fetchPosts({
          from_id: null,
          source: "profilePosts",
          username,
          ...(q && { q }),
        }),
      );
      dispatch(setIsRefetchNeeded({ source: "profilePosts", value: false }));
    }
  }, [username, isRefetchNeeded]);

  useEffect(() => {
    return () => {
      dispatch(resetProfilePosts());
      dispatch(setIsRefetchNeeded({ source: "profilePosts", value: true }));
    };
  }, [username]);

  const getNextPosts = () => {
    if (post_last_id) {
      dispatch(
        fetchNextPosts({
          from_id: post_last_id,
          source: "profilePosts",
          username,
          ...(q && { q: q }),
        }),
      );
    }
  };

  const onComment = ({ id, username }) => {
    navigate(generatePath(ROUTES.post, { id, username: username }));
  };

  const onPostAction = useCallback(
    ({ id, type, metadata }) => {
      switch (type) {
        case "comment": {
          onComment({ id: metadata.seq_id, username: metadata.username });
          break;
        }
        case "vote": {
          const { type: voteType } = metadata;
          dispatch(fetchUpdateVote({ postId: id, voteType }));
          break;
        }
        case "remove": {
          dispatch(fetchRemovePost({ postId: id }));
          break;
        }
        default:
          break;
      }
    },
    [dispatch],
  );

  return {
    posts,
    isLoading,
    error,
    onPostAction,
    getPosts: getNextPosts,
    hasMorePosts: hasMorePosts,
    isFetchingNextPage,
  };
};

export default useProfilePosts;
