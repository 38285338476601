import { useDispatch } from "react-redux";

import { fetchPostById } from "../../features/posts/posts-actions";
import ChannelProvider from "./channel-provider";

const PostsUpdateProvider = ({ children }) => {
  const dispatch = useDispatch();
  const onReceived = (data) => {
    switch (data.event) {
      case "published":
        dispatch(fetchPostById({ id: data.post_id }));
        break;
      default:
        break;
    }
  };

  return (
    <ChannelProvider channel={"PostChannel"} onReceived={onReceived}>
      {children}
    </ChannelProvider>
  );
};

export default PostsUpdateProvider;
