import { Link } from "react-router-dom";

import ROUTES from "../../../routing/routes";

export const headerMenuActions = {
  login: {
    key: "login",
    label: "Увійти",
  },
  profile: {
    key: "profile",
    label: "Мій профіль",
  },
  settings: {
    key: "settings",
    label: "Налаштування",
  },
  logout: {
    key: "logout",
    label: "Вихід",
  },
};

export const headerMenuItems = [
  {
    label: <Link to={ROUTES.profile}>{headerMenuActions.profile.label}</Link>,
    key: headerMenuActions.profile.key,
  },
  {
    label: (
      <Link to={`${ROUTES.settings}/profile`}>
        {headerMenuActions.settings.label}
      </Link>
    ),
    key: headerMenuActions.settings.key,
  },
  {
    type: "divider",
  },
  {
    label: headerMenuActions.logout.label,
    key: headerMenuActions.logout.key,
  },
];
