import React from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  createSearchParams,
} from "react-router-dom";

import CleanLayout from "../components/layouts/clean";
import DefaultLayout from "../components/layouts/default";
import { ScrollRestorationComponent } from "../components/scroll-restoration";
import { AccountSettings } from "../components/settings/account-settings";
import { ProfileSettings } from "../components/settings/profile-settings";
import { EmailConfirm } from "../pages/email-confirm";
import { ErrorPage } from "../pages/error";
import { Feed } from "../pages/feed";
import { NoPermissionPage } from "../pages/no-permission-page";
import { NotFoundPage } from "../pages/not-found-page";
import { OtherProfile } from "../pages/other-profile";
import { PostPage } from "../pages/post-page";
import { PrivacyPolicy } from "../pages/privacy-policy";
import { Profile } from "../pages/profile";
import { ResetPassword } from "../pages/reset-password";
import { Rules } from "../pages/rules";
import { Settings } from "../pages/settings";
import AssignedUserRoute from "./AssignedUserRoute";
import ROUTES from "./routes";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTES.home} element={<ScrollRestorationComponent />}>
      {/* Unassigned users can view global feed and post by id */}
      <Route element={<DefaultLayout withRightColumn />}>
        <Route
          index
          element={
            <Navigate
              to={{
                pathname: ROUTES.feed,
                search: createSearchParams({
                  type: "fresh",
                }).toString(),
              }}
            />
          }
        />
        <Route path={ROUTES.feed} element={<Feed />} />
        <Route path={ROUTES.post} element={<PostPage />} />
        {/* Routes only for assigned users */}
        <Route element={<AssignedUserRoute />}>
          <Route path={ROUTES.profile} element={<Profile />} />
        </Route>
        <Route path=":slag" element={<OtherProfile />} />
      </Route>
      <Route element={<DefaultLayout />}>
        {/* Routes only for assigned users */}
        <Route element={<AssignedUserRoute />}>
          <Route path={ROUTES.settings} element={<Settings />}>
            <Route
              path={ROUTES.profileSettings}
              element={<ProfileSettings />}
            />
            <Route
              path={ROUTES.accountSettings}
              element={<AccountSettings />}
            />
            <Route path={ROUTES.contentSettings} element={<div>content</div>} />
            <Route
              path={ROUTES.blackListSettings}
              element={<div>black list</div>}
            />
          </Route>
        </Route>

        <Route path={ROUTES.passwordReset} element={<ResetPassword />} />
        <Route path={ROUTES.emailConfirm} element={<EmailConfirm />} />
      </Route>

      <Route path={ROUTES.notFoundPage} element={<NotFoundPage />} />
      <Route path={ROUTES.noPermission} element={<NoPermissionPage />} />
      <Route path="*" element={<Navigate to={ROUTES.error} />} />
      <Route path={ROUTES.error} element={<ErrorPage />} />

      <Route element={<CleanLayout />}>
        <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={ROUTES.rules} element={<Rules />} />
      </Route>
    </Route>,
  ),
);

export default router;
