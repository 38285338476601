import { Input } from "antd";
import { CloseCircle, SearchNormal1 } from "iconsax-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  generatePath,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useGetSelfProfileState } from "../../../../features/api/user-api";
import { fetchPosts } from "../../../../features/posts/posts-actions";
import {
  setIsRefetchNeeded,
  setSearchPostsQuery,
} from "../../../../features/posts/posts-slice";
import useMatchWithRoutesCheck from "../../../../hooks/useMatchWithRoutesCheck";
import ROUTES from "../../../../routing/routes";
import * as $vars from "../../../../utils/constants/variables";
import cn from "../../../../utils/helpers/className";
import styles from "./search.module.scss";

const HeaderSearch = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isFeedPage = useMatch(ROUTES.feed);
  const isProfilePage = useMatch(ROUTES.profile);
  const isOtherProfilePage = useMatchWithRoutesCheck(ROUTES.otherProfile);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchValue = useSelector((state) => state.posts.searchPostsQuery);
  const [params] = useSearchParams();
  const routerParams = useParams();
  const { data: profile } = useGetSelfProfileState();
  const q = params.get("q");
  const determinePage = () => {
    if (isFeedPage) return "feedPosts";
    if (isProfilePage) return "profilePosts";
    if (isOtherProfilePage) return "otherProfilePosts";
    return "feedPosts";
  };

  const openSearch = () => {
    setIsSearchOpen(true);
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
  };

  const onSearchBlur = () => {
    if (!searchValue) {
      closeSearch();
    }
  };

  useEffect(() => {
    if (searchValue.displayValue && location.state?.saveSearchTerm !== true) {
      dispatch(
        setSearchPostsQuery({
          query: null,
          page: null,
          displayValue: "",
        }),
      );
    }
  }, [location]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    const page = determinePage();
    dispatch(
      setSearchPostsQuery({
        page: page,
        displayValue: value,
      }),
    );
  };

  const onEnterPress = () => {
    const page = determinePage();
    const searchParams = createSearchParams({
      ...(searchValue?.displayValue && { q: searchValue?.displayValue }),
    }).toString();

    const navigateWithSearch = (pathname) => {
      navigate(
        { pathname, search: searchParams },
        { state: { saveSearchTerm: true } },
      );
    };

    if (!isFeedPage && !isProfilePage && !isOtherProfilePage) {
      navigateWithSearch(ROUTES.feed);
    }
    if (isFeedPage) {
      navigateWithSearch(ROUTES.feed);
    }
    if (page === "feedPosts") {
      dispatch(
        fetchPosts({
          from_id: null,
          source: "feedPosts",
          category: "fresh",
          q: searchValue?.displayValue,
        }),
      );
    }
    if (isProfilePage || isOtherProfilePage) {
      const pathname = isProfilePage
        ? ROUTES.profile
        : generatePath(ROUTES.otherProfile, { slag: routerParams.slag });

      navigateWithSearch(pathname);

      dispatch(
        fetchPosts({
          from_id: null,
          source: page,
          q: searchValue?.displayValue,
          username: isProfilePage ? profile?.username : routerParams.slag,
        }),
      );
    }
  };

  useEffect(() => {
    if (!q && searchValue?.displayValue) {
      dispatch(setIsRefetchNeeded({ source: determinePage(), value: true }));
    }
  }, [q]);

  useEffect(() => {
    if (q !== searchValue?.displayValue) {
      dispatch(
        setSearchPostsQuery({
          query: null,
          page: null,
          displayValue: q,
        }),
      );
    }
  }, [q]);

  return (
    <div
      className={cn(
        styles.searchWrap,
        isSearchOpen ? styles.searchWrapShow : "",
      )}
    >
      <Input
        // allowClear
        placeholder={`Пошук по ${!isOtherProfilePage && !isProfilePage ? "всім постам" : "профілю"}`}
        className={cn(
          styles.searchInput,
          isSearchOpen ? styles.searchInputShow : "",
        )}
        value={searchValue?.displayValue}
        onPressEnter={onEnterPress}
        onBlur={onSearchBlur}
        onChange={handleSearchChange}
      />
      <div className={styles.searchButton}>
        {!isSearchOpen && (
          <SearchNormal1
            onClick={openSearch}
            size="24"
            color={$vars.colors.grey}
          />
        )}
        {isSearchOpen && (
          <CloseCircle
            onClick={closeSearch}
            size="24"
            color={$vars.colors.grey}
          />
        )}
      </div>
    </div>
  );
};

export { HeaderSearch };
