import { Dropdown, Flex, notification } from "antd";
import { ArrowForward, DocumentCopy, MessageText1 } from "iconsax-react";
import React, { useMemo } from "react";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";

import { Votes } from "../../ui/votes";
import { POST_ACTION_TYPES } from "../constants";
import styles from "./post-actions.module.scss";

import { ReactComponent as ShareIcon } from "@/assets/icons/systems/share.svg"

const shareItems = [
  // {
  //   key: 'repost',
  //   label: 'Зробити репост',
  //   icon: <ArrowForward />,
  // },
  {
    key: "copy",
    label: "Скопіювати посилання",
    icon: <DocumentCopy />,
  },
];

const PostActions = ({
  onAction,
  votesCount,
  activeVote,
  permissions,
  counters,
  shareUrl,
  postLink,
}) => {
  const dropdownItems = useMemo(() => {
    return [
      {
        key: POST_ACTION_TYPES.EDIT,
        label: "Редагувати",
        visible: permissions.can_edit,
      },
      {
        key: POST_ACTION_TYPES.REPORT,
        label: "Поскаржитись",
        visible: permissions.can_report,
      },
      {
        key: POST_ACTION_TYPES.REMOVE_REQUEST,
        label: "Видалити",
        danger: true,
        visible: permissions.can_delete,
      },
    ]
      .filter((item) => item.visible)
      .map(({ visible, ...rest }) => rest);
  }, [permissions]);

  const [notificationsApi, contextHolder] = notification.useNotification();

  const onShareAction = async (type) => {
    switch (type.key) {
      case "copy": {
        const postUrl = `${location.origin}${shareUrl}`;
        await navigator.clipboard.writeText(postUrl);
        notificationsApi.success({
          message: "Системне оповіщення",
          description: "Посилання скопійовано",
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      {contextHolder}
      <Flex className={styles.postFooter} align={"center"} gap={8}>
        <Votes
          onVote={(voteType) => onAction("vote", { type: voteType })}
          activeVote={activeVote}
          votesCount={votesCount}
        />
        <Flex gap={8} align={"center"}>
          <Flex className={styles.actionButton} align="center">
            <Link to={postLink} className={styles.actionIcon}>
              <MessageText1 size="18" />
            </Link>
            <div className={styles.actionLabel}>{counters.comments_count}</div>
          </Flex>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: shareItems,
              onClick: onShareAction,
            }}
            placement={"top"}
            arrow
          >
            <div className={styles.footerButton}>
              <ShareIcon/>
            </div>
          </Dropdown>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: dropdownItems,
              onClick: (e) => onAction(e.key),
            }}
            placement="top"
            arrow
          >
            <div className={styles.footerButton}>
              <BsThreeDots size={18} />
            </div>
          </Dropdown>
        </Flex>
      </Flex>
    </>
  );
};

export { PostActions };
