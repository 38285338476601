import axios from "axios";

export const signInByGoogle = async (token) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/users/oauth2/google`,
    { token },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const updateUserPassword = async ({ reset_token, password }) => {
  return await axios.put(`${process.env.REACT_APP_API_URL}/users/password`, {
    reset_token,
    password,
    password_confirmation: password,
  });
};

export const restorePassword = async (email) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/users/password`, {
    email,
  });
};
