import { Popover } from "antd";
import React from "react";

import PopoverUser from "../popovers/popover-user";

const ProfileInfoTooltip = ({ children, username }) => {
  return (
    <Popover
      mouseEnterDelay={0.7}
      placement="bottomLeft"
      content={<PopoverUser slug={username} />}
      arrow={false}
    >
      {children}
    </Popover>
  );
};

export { ProfileInfoTooltip };
