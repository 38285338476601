import { Button, Typography } from "antd";
import { ArrowCircleDown } from "iconsax-react";
import React from "react";

import { useGetSelfProfileState } from "../../../../../features/api/user-api";
import * as vars from "../../../../../utils/constants/variables";
import useRestorePassword from "../../../../auth/start/hooks/use-restore-password";
import styles from "./reset-loggedIn-user-password.module.scss";

const ResetLoggedInUserPassword = () => {
  const [success, setSuccess] = React.useState(false);
  const { data: profile } = useGetSelfProfileState();
  const { trigger: handleRestorePassword, error } = useRestorePassword();

  const onChangePassword = async () => {
    await handleRestorePassword(profile.email).then(() => {
      setSuccess(true);
    });
  };

  return (
    <div className={styles.wrapper}>
      {success && (
        <div className={styles.success}>
          <ArrowCircleDown size="32" color={vars.colors.primaryColor} />
          <Typography>
            Посилання надіслано на <b>{profile.email}</b>
          </Typography>
        </div>
      )}
      {!success && (
        <>
          <Typography>
            Для забезпечення безпеки вашого акаунта рекомендуємо регулярно
            оновлювати пароль.
          </Typography>
          <Typography>
            Після натискання на вашу пошту буде надіслано лист з посиланням на
            зміну пароля.
          </Typography>
          <Button type="default" size="large" className={styles.button} onClick={onChangePassword}>
            Змінити пароль
          </Button>
        </>
      )}
      {error && (
        <Typography className={styles.error}>{error.message}</Typography>
      )}
    </div>
  );
};

export { ResetLoggedInUserPassword };
