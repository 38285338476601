import { Input, notification } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";

import usePasteHandler from "../../../hooks/use-paste-handler";
import {
  getPreSignedUrl,
  uploadToPresignedUrl,
} from "../../../services/upload-service";
import cn from "../../../utils/helpers/className.js";
import { getAllErrors } from "../../../utils/helpers/string-transform";
import { AttachmentsList } from "../../ui/attachments-list";
import { CommentFormFooter } from "../../ui/comment-form-footer";
import { DragAndDropWrapper } from "../../ui/drag-and-drop-wrapper";
import styles from "./comment-form.module.scss";

const CommentForm = ({
 comment,
 onSubmit,
 isEditing,
 onCancel,
}) => {
  const [attachments, setAttachments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const uploadInputRef = useRef(null);
  const formRef = useRef(null);
  const inputRef = useRef(null)
  const [notificationApi, contextHolder] = notification.useNotification();
  const onUploadPercentChange = (id, progress) => {
    setAttachments((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            process: progress,
          };
        }
        return item;
      });
    });
  };

  const createAttachmentStateItem = async (file) => {
    try {
      const name = file.name;
      const { url, fields } = await getPreSignedUrl(name);
      setAttachments((prev) => [
        ...prev,
        {
          id: fields.key,
          file,
          process: 0,
        },
      ]);

      uploadToPresignedUrl(url, file, fields, (progress) =>
        onUploadPercentChange(fields.key, progress),
      );
    } catch (e) {
      getAllErrors(e.response.data).forEach((item) => {
        notificationApi.error({
          message: item,
        });
      });
    }
  };

  const onAddFile = async (e) => {
    const files = e.target.files;
    for await (const file of files) {
      await createAttachmentStateItem(file);
    }
    e.target.value = null;
  };

  const onUploadClick = () => {
    uploadInputRef.current.click();
  };

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      ...values,
      files: attachments.map((item) => item.id),
    };
    if(values.body.length || attachments?.length){
      setIsSubmitting(true);
      await onSubmit({ data })
        .then((res) => {
          if (!res?.payload?.errors?.length) {
            resetForm();
            setAttachments([]);
            onCancel()
          } else {
            res?.payload?.errors.forEach((item) => {
              notificationApi.error({
                message: item,
              });
            });
          }
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
        });
    }
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    let handled = false;

    for (const item of items) {
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          createAttachmentStateItem(file);
          handled = true;
        }
      }
    }

    if (!handled) {
      const pastedText = event.clipboardData.getData("text/plain");
      if (pastedText && !formRef?.current?.values?.body && formRef?.current) {
        formRef.current.setFieldValue("body", pastedText);
        event.preventDefault();
      }
    }
  };

  usePasteHandler(handlePaste, formRef);

  useEffect(() => {
    if (comment?.external_images?.length) {
      setAttachments(comment?.external_images);
    }
  }, [comment]);

  useEffect(() => {
    if(inputRef?.current){
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      {contextHolder}
      <DragAndDropWrapper onDropFile={createAttachmentStateItem}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            body: comment?.body || "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, resetForm }) => (
            <Form
              className={cn(
                styles.form,
                !attachments.length && styles.noAttachments,
              )}
            >
              <div className={cn(styles.formPostWrap)}>
                <Field name="body">
                  {({ field }) => (
                    <Input.TextArea
                      ref={inputRef}
                      className={styles.formTextarea}
                      {...field}
                      autoSize={true}
                      onKeyDown={(e) => {
                        if(e.ctrlKey && e.key === "Enter" && (values.body.length || attachments.length ) && !isSubmitting) {
                          formRef.current.submitForm();
                        }
                      }}
                      placeholder="Написати коментар"
                    />
                  )}
                </Field>
                <div>
                  <input
                    ref={uploadInputRef}
                    type="file"
                    accept="image/*"
                    onChange={onAddFile}
                    multiple
                    style={{ display: "none" }}
                  />
                  <AttachmentsList
                    attachments={attachments}
                    onRemoveAttachment={(image) => {
                      setAttachments((prev) =>
                        prev.filter((item) => item.id !== image),
                      );
                    }}
                  />
                </div>
              </div>
              <CommentFormFooter
                isEditing={isEditing}
                onCancel={() => {
                  setAttachments([]);
                  resetForm();
                  onCancel && onCancel();
                }}
                onUploadClick={onUploadClick}
                isSubmitDisabled={
                  (!values.body.trim() && attachments.length === 0) ||
                  attachments.some(attachment => attachment.process < 100) ||
                  isSubmitting
                }
              />
            </Form>
          )}
        </Formik>
      </DragAndDropWrapper>
    </>
  );
};

export { CommentForm };
