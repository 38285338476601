import { Flex } from "antd";
import { Link } from "react-router-dom";

import styles from "./aside-links.module.scss";
import ROUTES from "../../../routing/routes";

const AsideLinks = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        <Flex vertical gap={12}>
          <a href="https://forms.gle/7E8TRPBC3dSx33SG9" target="_blank" rel="noreferrer" className={styles.link}>
            Повідомити про баг
          </a>
          <Link to={ROUTES.rules} target="_blank" rel="noreferrer" className={styles.link}>
            Правила
          </Link>
        </Flex>
      </div>
    </div>
  );
};

export default AsideLinks;
