import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import { useGetSelfProfileState } from "../features/api/user-api";
import {
  fetchNextPosts,
  fetchPosts,
  fetchRemovePost,
  fetchUpdateVote,
} from "../features/posts/posts-actions";
import {
  isFetchingNextFeedPosts,
  selectFeedError,
  selectFeedIsLoading,
  selectFeedPostLastId,
  selectFeedPosts,
  selectHasMoreFeedPosts,
  setIsRefetchNeeded,
} from "../features/posts/posts-slice";
import ROUTES from "../routing/routes";
import { load } from "../utils/helpers/local-storage";

const useFeedPosts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const posts = useSelector(selectFeedPosts);
  const post_last_id = useSelector(selectFeedPostLastId);
  const hasMorePosts = useSelector(selectHasMoreFeedPosts);
  const isFetchingNextPage = useSelector(isFetchingNextFeedPosts);
  const error = useSelector(selectFeedError);
  const isLoading = useSelector(selectFeedIsLoading);
  const isRefetchNeeded = useSelector(
    (state) => state.posts?.feedPosts.isRefetchNeeded,
  );
  const { data: profile } = useGetSelfProfileState();
  const [params] = useSearchParams();
  const type = params.get("type");
  const q = params.get("q");

  useEffect(() => {
    if (isRefetchNeeded) {
      const token = load("accessToken");
      const category = token ? type || "fresh" : "fresh";
      dispatch(
        fetchPosts({
          from_id: null,
          source: "feedPosts",
          category,
          ...(q && { q }),
        }),
      );
      dispatch(setIsRefetchNeeded({ source: "feedPosts", value: false }));
    }
  }, [profile, isRefetchNeeded]);

  const getNextPosts = () => {
    if (post_last_id) {
      const category = type && load("accessToken") ? type : "fresh";
      dispatch(
        fetchNextPosts({
          from_id: post_last_id,
          source: "feedPosts",
          ...(q ? { q: q } : {}),
          category,
        }),
      );
    }
  };

  const onComment = ({ id, metadata: { username, seq_id } }) => {
    navigate(generatePath(ROUTES.post, { id: seq_id, username }));
  };

  const onPostAction = useCallback(
    ({ id, type, metadata }) => {
      try {
        switch (type) {
          case "comment": {
            onComment({ id, metadata });
            break;
          }
          case "vote": {
            const { type: voteType } = metadata;
            dispatch(fetchUpdateVote({ postId: id, voteType }));
            break;
          }
          case "remove": {
            dispatch(fetchRemovePost({ postId: id }));
            break;
          }
          default:
            console.warn("Unknown post action type:", type);
            break;
        }
      } catch (err) {
        console.error("Error in onPostAction:", err);
      }
    },
    [dispatch],
  );

  return {
    posts,
    isLoading,
    error,
    onPostAction,
    getPosts: getNextPosts,
    hasMorePosts,
    isFetchingNextPage,
  };
};

export default useFeedPosts;
