import styles from './bottom-modal.module.scss';
import { Modal } from "antd";

const BottomModal = ({
    open,
    onOk,
    onCancel,
    okText,
    cancelText,
    children,
    title,
    centered,
    rootClassName,
    maskClosable,
    header,
    footer,
    closable,
    width,
  }) => {
  return (
    <Modal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      width={width}
      title={title}
      header={header}
      footer={footer}
      centered={centered}
      maskClosable={maskClosable}
      rootClassName={rootClassName}
      wrapClassName={styles.wrapper}
      className={styles.modal}
      closable={closable}
      transitionName=""
    >
      {children}
    </Modal>
  )
}

export { BottomModal };