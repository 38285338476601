import { configureStore } from "@reduxjs/toolkit";

import router from "../routing";
import { api } from "./api/api";
import { rootReducer } from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          router,
        },
      },
    }).concat(api.middleware),
});
