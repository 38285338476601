import React from "react";

import { SettingsItemContainer } from "../shared/settings-item-container";
import { DeactivateAccount } from "./ui/deactivate-account";
import { ResetLoggedInUserPassword } from "./ui/reset-loggedIn-user-password/reset-loggedIn-user-password";
import { Flex } from "antd";

const AccountSettings = () => {
  return (
    <Flex vertical gap={20}>
      <SettingsItemContainer title={"Зміна паролю"}>
        <ResetLoggedInUserPassword />
      </SettingsItemContainer>
      <SettingsItemContainer title={"Видалення акаунту"}>
        <DeactivateAccount />
      </SettingsItemContainer>
    </Flex>
  );
};

export { AccountSettings };
