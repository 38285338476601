import { createSlice } from "@reduxjs/toolkit";

import { rootReducer } from "../rootReducer";
import {
  fetchCommentVote,
  fetchComments,
  fetchNextThreads,
  fetchPostComment,
  fetchRemoveComment,
  fetchThreadsForPost,
  fetchUpdateComment,
  refetchOneComment,
} from "./comment-actions";

const initialState = {
  isLoading: true,
  data: [],
  error: null,
  comment_first_id: null,
  comment_last_id: null,
  is_loading_next: false,
};

export const commentSlice = createSlice({
  name: "postComments",
  initialState,
  reducers: {
    resetComments: () => initialState,
  },
  selectors: {
    selectComments: (state) => state.data,
    selectCommentsFirstId: (state) => state.comment_first_id,
    selectCommentsLastId: (state) => state.comment_last_id,
    selectCommentsLeft: (state) => state.comments_left,
    selectIsCommentsLoading: (state) => state.isLoading,
    selectIsCommentsLoadingNext: (state) => state.is_loading_next,
    selectCommentsError: (state) => state.error,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThreadsForPost.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload.comments;
      state.comment_first_id = action.payload.comment_first_id;
      state.comment_last_id = action.payload.comment_last_id;
      state.comments_left = action.payload.comments_left;
    });
    builder.addCase(fetchThreadsForPost.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.data = [];
      state.comment_first_id = null;
      state.comment_last_id = null;
      state.comments_left = null;
    });
    builder.addCase(fetchThreadsForPost.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
      state.comment_first_id = null;
      state.comment_last_id = null;
      state.comments_left = null;
    });
    builder.addCase(fetchNextThreads.fulfilled, (state, action) => {
      state.is_loading_next = false;
      state.error = null;
      state.data = [...state.data, ...action.payload.comments];
      if (action.payload.comment_last_id) {
        state.comment_last_id = action.payload.comment_last_id;
      }
      state.comments_left = action.payload.comments_left;
    });
    builder.addCase(fetchNextThreads.pending, (state) => {
      state.is_loading_next = true;
      state.error = null;
    });
    builder.addCase(fetchNextThreads.rejected, (state) => {
      state.is_loading_next = false;
      state.error = "Something went wrong";
    });
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      const threadId = action.meta.arg.parent_id;
      const currentTread = state.data.find(
        (item) => item.id === Number(threadId),
      );
      currentTread.comments.push(...action.payload.comments);
      currentTread.comments_left = action.payload.comments_left;
      currentTread.comment_first_id = action.payload.comment_first_id;
      currentTread.comment_last_id = action.payload.comment_last_id;
      currentTread.isLoading = false;
      const newComments = currentTread?.comments.filter(
        (a) => !action.payload?.comments?.some((b) => a.id === b.id),
      );
      currentTread.comments = [...newComments, ...action.payload.comments];
    });
    builder.addCase(fetchComments.pending, (state, action) => {
      const threadId = action.meta.arg.parent_id;
      const currentTread = state.data.find(
        (item) => item.id === Number(threadId),
      );
      currentTread.isLoading = true;
    });
    builder.addCase(fetchComments.rejected, (state, action) => {
      const threadId = action.meta.arg.parent_id;
      const currentTread = state.data.find(
        (item) => item.id === Number(threadId),
      );
      currentTread.isLoading = false;
      currentTread.error = "Something went wrong";
    });
    builder.addCase(fetchPostComment.fulfilled, (state, action) => {
      const isThread = action.payload.thread_id === null;
      if (isThread) {
        state.data.unshift(action.payload);
        state.comment_last_id = action.payload.id;
      } else {
        const threadId = action.payload.thread_id;
        const currentTread = state.data.find(
          (item) => item.id === Number(threadId),
        );
        currentTread.comments =
          currentTread?.comments?.length > 0
            ? [...currentTread.comments, action.payload]
            : [action.payload];
      }
    });
    builder.addCase(fetchPostComment.pending, () => {});
    builder.addCase(fetchPostComment.rejected, () => {});
    builder.addCase(fetchCommentVote.fulfilled, (state, action) => {
      const commentId = action.meta.arg.comment_id;
      const parentId = action.meta.arg.parentId;

      if (!parentId) {
        const currentTread = state.data.find(
          (item) => item.id === Number(commentId),
        );
        currentTread.vote = action.payload;
      } else {
        const currentTread = state.data.find(
          (item) => item.id === Number(parentId),
        );
        const currentComment = currentTread.comments.find(
          (item) => item.id === Number(commentId),
        );
        currentComment.vote = action.payload;
      }
    });
    builder.addCase(fetchCommentVote.pending, (state, action) => {
      const commentId = action.meta.arg.comment_id;
      const parentId = action.meta.arg.parentId;

      if (!parentId) {
        const currentTread = state.data.find(
          (item) => item.id === Number(commentId),
        );
        currentTread.vote.vote_type = action.meta.arg.vote_type;
      } else {
        const currentTread = state.data.find(
          (item) => item.id === Number(parentId),
        );
        const currentComment = currentTread.comments.find(
          (item) => item.id === Number(commentId),
        );
        currentComment.vote.vote_type = action.meta.arg.vote_type;
      }
    });
    builder.addCase(fetchCommentVote.rejected, () => {});
    builder.addCase(fetchRemoveComment.fulfilled, (state, action) => {
      const commentId = action.meta.arg.comment_id;
      const threadId = action.meta.arg.thread_id;
      if (!threadId) {
        state.data = state.data.filter((item) => item.id !== Number(commentId));
      } else {
        const currentTread = state.data.find(
          (item) => item.id === Number(threadId),
        );
        currentTread.comments = currentTread.comments.map((item) => {
          if (item.id === Number(commentId)) {
            return action.payload;
          }
          return item;
        });
      }
    });
    builder.addCase(fetchRemoveComment.pending, () => {});
    builder.addCase(fetchRemoveComment.rejected, () => {});
    builder.addCase(refetchOneComment.fulfilled, (state, action) => {
      const commentId = action.meta.arg.comment_id;
      const threadId = action.meta.arg.thread_id;
      if (!threadId) {
        const currentTreadIndex = state.data.findIndex(
          (item) => item.id === Number(commentId),
        );
        if (currentTreadIndex !== -1) {
          state.data[currentTreadIndex] = {
            ...action.payload,
            //for save thread because backend doesn't send it
            comments: state.data[currentTreadIndex].comments,
          };
        }
      } else {
        const currentTread = state.data.find(
          (item) => item.id === Number(threadId),
        );
        let currentCommentIndex = currentTread.comments.findIndex(
          (item) => item.id === Number(commentId),
        );
        if (currentCommentIndex !== -1) {
          currentTread.comments[currentCommentIndex] = action.payload;
        }
      }
    });
    builder.addCase(fetchUpdateComment.fulfilled, (state, action) => {
      const commentId = action.meta.arg.comment_id;
      const threadId = action.meta.arg.thread_id;
      if (!threadId) {
        const currentTreadIndex = state.data.findIndex(
          (item) => item.id === Number(commentId),
        );
        if (currentTreadIndex !== -1) {
          state.data[currentTreadIndex] = action.payload;
        }
      } else {
        const currentTread = state.data.find(
          (item) => item.id === Number(threadId),
        );
        let currentCommentIndex = currentTread.comments.findIndex(
          (item) => item.id === Number(commentId),
        );
        if (currentCommentIndex !== -1) {
          currentTread.comments[currentCommentIndex] = action.payload;
        }
      }
    });
  },
}).injectInto(rootReducer);

export const { resetComments } = commentSlice.actions;

export const selectComments = commentSlice.selectors.selectComments;

export const selectIsCommentsLoading =
  commentSlice.selectors.selectIsCommentsLoading;

export const selectCommentsError = commentSlice.selectors.selectCommentsError;

export const selectCommentsLeft = commentSlice.selectors.selectCommentsLeft;

export const selectCommentsLastId = commentSlice.selectors.selectCommentsLastId;

export const selectIsCommentsLoadingNext =
  commentSlice.selectors.selectIsCommentsLoadingNext;
