import { createSelector, createSlice } from "@reduxjs/toolkit";

import { rootReducer } from "../rootReducer";
import {
  fetchCreatePost,
  fetchNextPosts,
  fetchPostById,
  fetchPosts,
  fetchRemovePost,
  fetchUpdatePost,
  fetchUpdateVote,
} from "./posts-actions";

const initialState = {
  searchPostsQuery: {
    displayValue: "",
  },
  posts: {},
  feedPosts: {
    ids: [],
    post_last_id: null,
    post_first_id: null,
    isLoading: true,
    error: null,
    isFetchingNextPage: false,
    isRefetchNeeded: true,
  },
  profilePosts: {
    ids: [],
    post_last_id: null,
    post_first_id: null,
    isLoading: true,
    error: null,
    isFetchingNextPage: false,
    isRefetchNeeded: true,
  },
  otherProfilePosts: {
    ids: [],
    post_last_id: null,
    post_first_id: null,
    isLoading: true,
    error: null,
    isFetchingNextPage: false,
    isRefetchNeeded: true,
  },
};

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    removePostFromState: (state, action) => {
      state.feedPosts.ids = state.feedPosts.ids.filter(
        (id) => id !== Number(action.payload),
      );
      state.profilePosts.ids = state.profilePosts.ids.filter(
        (id) => id !== Number(action.payload),
      );
      delete state.posts[action.payload];
    },
    resetOtherProfilePosts: (state) => {
      state.otherProfilePosts.ids = [];
      state.otherProfilePosts.post_last_id = null;
      state.otherProfilePosts.post_first_id = null;
    },
    resetProfilePosts: (state) => {
      state.profilePosts.ids = [];
      state.profilePosts.post_last_id = null;
      state.profilePosts.post_first_id = null;
    },
    resetFeedPosts: (state) => {
      state.feedPosts.ids = [];
      state.feedPosts.post_last_id = null;
      state.feedPosts.post_first_id = null;
    },
    setSearchPostsQuery: (state, action) => {
      state.searchPostsQuery = action.payload;
    },
    setIsRefetchNeeded: (state, action) => {
      state[action.payload.source].isRefetchNeeded = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.fulfilled, (state, action) => {
        const source = action.meta.arg.source;
        if (action.payload.posts.length > 0) {
          action.payload.posts.forEach((post) => {
            state.posts[post.id] = { ...state.posts[post.id], ...post };
          });
        }
        if (source) {
          state[source].ids = action.payload.posts.map((post) => post.id);
          state[source].isLoading = false;
          state[source].post_last_id = action.payload.post_last_id;
          state[source].post_first_id = action.payload.post_first_id;
        }
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state[action.meta.arg.source].error = action.error;
      })
      .addCase(fetchPosts.pending, (state, action) => {
        state[action.meta.arg.source].isLoading = true;
      })
      .addCase(fetchNextPosts.fulfilled, (state, action) => {
        const source = action.meta.arg.source;
        if (action.payload.posts.length > 0) {
          action.payload.posts.forEach((post) => {
            state.posts[post.id] = { ...state.posts[post.id], ...post };
          });
        }
        if (source) {
          state[source].ids = state[source].ids.concat(
            action.payload.posts.map((post) => post.id),
          );
          state[source].isFetchingNextPage = false;
          state[source].post_last_id = action.payload.post_last_id;
          state[source].post_first_id = action.payload.post_first_id;
        }
      })
      .addCase(fetchNextPosts.rejected, (state, action) => {
        state[action.meta.arg.source].error = action.error;
        state[action.meta.arg.source].isFetchingNextPage = false;
      })
      .addCase(fetchNextPosts.pending, (state, action) => {
        state[action.meta.arg.source].isFetchingNextPage = true;
      })
      .addCase(fetchUpdateVote.fulfilled, (state, action) => {
        const postId = action.meta.arg.postId;
        state.posts[postId].vote = {
          vote_type: action.payload.vote_type,
          vote_sum: action.payload.vote_sum,
        };
      })
      .addCase(fetchUpdateVote.rejected, (state, action) => {})
      .addCase(fetchUpdateVote.pending, (state, action) => {
        const postId = action.meta.arg.postId;
        const voteType = action.meta.arg.voteType;
        state.posts[postId].vote.vote_type = voteType;
      })
      .addCase(fetchRemovePost.fulfilled, (state, action) => {
        const postId = action.meta.arg.postId;
        delete state.posts[postId];
        state.feedPosts.ids = state.feedPosts.ids.filter((id) => id !== postId);
        state.profilePosts.ids = state.profilePosts.ids.filter(
          (id) => id !== postId,
        );
      })
      .addCase(fetchRemovePost.rejected, (state, action) => {})
      .addCase(fetchRemovePost.pending, (state, action) => {})
      .addCase(fetchCreatePost.fulfilled, (state, action) => {
        const postId = action.payload.id;
        const source = action.meta.arg.source;
        state.posts[postId] = action.payload;
        //for update posts after create
        //when ids if empty, means it's the first
        // page entered and posts will request from server
        if (source === "feedPosts") {
          state.feedPosts.ids = [postId, ...state.feedPosts.ids];
          state.profilePosts.ids = [];
        } else if (source === "profilePosts") {
          state.profilePosts.ids = [postId, ...state.profilePosts.ids];
          state.feedPosts.ids = [];
        }
      })
      .addCase(fetchUpdatePost.fulfilled, (state, action) => {
        state.posts[action.payload.id] = action.payload;
      })
      .addCase(fetchUpdatePost.rejected, (state, action) => {})
      .addCase(fetchUpdatePost.pending, (state, action) => {})
      .addCase(fetchPostById.fulfilled, (state, action) => {
        state.posts[action.payload.id] = action.payload;
      });
  },
}).injectInto(rootReducer);

export const selectAllPosts = (state) => state.posts.posts;

export const selectFeedPostIds = (state) => state.posts.feedPosts.ids;
export const selectProfilePostIds = (state) => state.posts.profilePosts.ids;
export const selectOtherProfilePostIds = (state) =>
  state.posts.otherProfilePosts.ids;
export const selectFeedPostLastId = (state) =>
  state.posts.feedPosts.post_last_id;

export const selectProfilePostLastId = (state) =>
  state.posts.profilePosts.post_last_id;

export const selectOtherProfilePostLastId = (state) =>
  state.posts.otherProfilePosts.post_last_id;

export const isFetchingNextFeedPosts = (state) =>
  state.posts.feedPosts.isFetchingNextPage;

export const isFetchingNextProfilePosts = (state) =>
  state.posts.profilePosts.isFetchingNextPage;

export const isFetchingNextOtherProfilePosts = (state) =>
  state.posts.profilePosts.isFetchingNextPage;

export const selectFeedError = (state) => state.posts.feedPosts.error;
export const selectProfileError = (state) => state.posts.profilePosts.error;
export const selectOtherProfileError = (state) =>
  state.posts.otherProfilePosts.error;
export const selectFeedIsLoading = (state) => state.posts.feedPosts.isLoading;

export const selectProfileIsLoading = (state) =>
  state.posts.profilePosts.isLoading;

export const selectOtherProfileIsLoading = (state) =>
  state.posts.otherProfilePosts.isLoading;

export const selectHasMoreFeedPosts = createSelector(
  [selectFeedPostLastId, isFetchingNextFeedPosts],
  (post_last_id, isFetchingNextPage) => {
    return !isFetchingNextPage && post_last_id !== null;
  },
);

export const selectHasMoreProfilePosts = createSelector(
  [selectProfilePostLastId, isFetchingNextProfilePosts],
  (post_last_id, isFetchingNextPage) => {
    return !isFetchingNextPage && post_last_id !== null;
  },
);

export const selectHasMoreOtherProfilePosts = createSelector(
  [selectOtherProfilePostLastId, isFetchingNextOtherProfilePosts],
  (post_last_id, isFetchingNextPage) => {
    return !isFetchingNextPage && post_last_id !== null;
  },
);

export const selectFeedPosts = createSelector(
  [selectAllPosts, selectFeedPostIds],
  (allPosts, feedPostIds) => {
    return feedPostIds.map((id) => {
      return allPosts[id];
    });
  },
);

export const selectProfilePosts = createSelector(
  [selectAllPosts, selectProfilePostIds],
  (allPosts, profilePostIds) => {
    return profilePostIds.map((id) => {
      return allPosts[id];
    });
  },
);

export const selectOtherProfilePosts = createSelector(
  [selectAllPosts, selectOtherProfilePostIds],
  (allPosts, profilePostIds) => {
    return profilePostIds.map((id) => {
      return allPosts[id];
    });
  },
);

export const {
  removePostFromState,
  resetOtherProfilePosts,
  resetFeedPosts,
  resetProfilePosts,
  setSearchPostsQuery,
  setIsRefetchNeeded,
} = postsSlice.actions;
export default postsSlice.reducer;
