import axios from "axios";

import { openAuthModal } from "../features/auth/user-slice";
import { load, remove, save } from "../utils/helpers/local-storage";

let store;

export const injectStore = (_store) => {
  store = _store;
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null, refreshToken = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve({ token, refreshToken });
    }
  });

  failedQueue = [];
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = load("accessToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["cache-control"] = `no-cache`;
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => {
    const newToken = response.headers.get("Authorization");
    const newRefreshToken = response.headers.get("Refresh-Token");

    if (newToken) {
      save("accessToken", newToken);
    }
    if (newRefreshToken) {
      save("refreshToken", newRefreshToken);
    }

    return response;
  },
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (response && response.status === 403) {
      remove("accessToken");
      remove("refreshToken");

      return Promise.reject(error);
    }
    if (
      response &&
      response.status === 401 &&
      !originalRequest._retry &&
      window.location.pathname !== "/start" &&
      window.location.pathname !== "/start/sign-up" &&
      window.location.pathname !== "/start/restore"
    ) {
      const refreshToken = load("refreshToken");

      if (!refreshToken) {
        remove("accessToken");
        store.dispatch(openAuthModal());
        return Promise.reject(error);
      }

      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        try {
          originalRequest.headers["Refresh-Token"] = refreshToken;
          const response = await axiosInstance(originalRequest);

          const newAccessToken = response.headers["authorization"];
          const newRefreshToken = response.headers["refresh-token"];

          processQueue(null, newAccessToken, newRefreshToken);
          isRefreshing = false;

          return response;
        } catch (err) {
          processQueue(err, null, null);
          isRefreshing = false;
          return Promise.reject(err);
        }
      }

      return new Promise((resolve, reject) => {
        failedQueue.push({
          resolve: () => {
            resolve(axiosInstance(originalRequest));
          },
          reject: (err) => reject(err),
        });
      });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
