import React from "react";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import {
  setIsRefetchNeeded,
  setSearchPostsQuery,
} from "../../../../features/posts/posts-slice";
import ROUTES from "../../../../routing/routes";

const Hashtag = ({ value }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onTagClick = (e) => {
    e.preventDefault();
    dispatch(
      setSearchPostsQuery({
        query: null,
        page: null,
        displayValue: value,
      }),
    );
    navigate(
      {
        pathname: ROUTES.feed,
        search: createSearchParams({
          type: "fresh",
          q: value,
        }).toString(),
      },
      { state: { saveSearchTerm: true } },
    );
    dispatch(
      setIsRefetchNeeded({
        source: "feedPosts",
        value: true,
      }),
    );
  };

  return <a onClick={onTagClick}>{value}</a>;
};

export { Hashtag };
