import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { usePostComments } from "../../../pages/post-page/hooks/usePostComments";
import styles from "../../../pages/post-page/post-page.module.scss";
import { Spinner } from "../../ui/spinner";
import { CommentsList } from "../comments-list";

const PostComments = ({ id, hidden }) => {
  const {
    handleRemove,
    handleReply,
    handleVote,
    handleLoadNextComments,
    handleLoadNextThreadComments,
    isLoading: isLoadingThreads,
    error: threadsError,
    isLoadingNext: isLoadingNextThreads,
    data: threads,
    commentsLeft: numberOfNotLoadedThreads,
  } = usePostComments({ id });

  const commentsLoadingErrorMessageVisible =
    threadsError && !isLoadingThreads && !isLoadingNextThreads;

  if (hidden) {
    return null;
  }

  return (
    <div>
      {isLoadingThreads && (
        <div className={styles.infinitiLoaderWrapper}>
          <Spinner />
        </div>
      )}
      {!isLoadingThreads && threads?.length !== 0 && (
        <div className={styles.commentsWrap}>
          <InfiniteScroll
            initialLoad={false}
            loadMore={handleLoadNextComments}
            hasMore={Boolean(
              numberOfNotLoadedThreads > 0 && !isLoadingNextThreads,
            )}
            threshold={500}
          >
            <CommentsList
              threads={threads}
              onVote={handleVote}
              onReply={handleReply}
              onRemove={handleRemove}
              handleLoadNextThreadComments={handleLoadNextThreadComments}
            />
          </InfiniteScroll>
        </div>
      )}
      {commentsLoadingErrorMessageVisible && (
        <div className={styles.wrapperCentered}>{threadsError}</div>
      )}
      {isLoadingNextThreads && (
        <div className={styles.infinitiLoaderWrapper}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export { PostComments };
